/* eslint-disable */
import router from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';
import axios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import VueAuth from '@websanova/vue-auth/src/v2';
import { isNil, isEmpty } from 'lodash-es';
import AccessControl from 'accesscontrol';
import IdleJs from 'idle-js';
import store from '@store';
import Vue from 'vue';

import { parseJwt } from '@utils';

const redirectUrl = (userId) => {
  const securityPolicy = store?.getters['auth/securityPolicy'];

  let redirect = '/login';

  if (securityPolicy && securityPolicy[userId]) {
    redirect = `/login/sso?securityPolicyId=${securityPolicy[userId]}`;
  }

  return redirect;
};

const setRefreshAccessTokenHandler = () => {
  const { auth } = Vue;

  if (auth) {
    const accessToken = auth.token();
    const refreshToken = auth.token(auth.options.refreshTokenName);
    const refreshTokenExpiresAt = parseInt(auth.token(auth.options.refreshTokenExpiresAtName), 10);
    const accessTokenParsed = parseJwt(accessToken);
    const { email, userId } = accessTokenParsed;
    const accessTokenExpiresAt = accessTokenParsed.exp * 1000;
    const now = Date.now();

    if (refreshTokenExpiresAt < now) {
      auth
        .logout({
          makeRequest: false,
        })
        .then(() => {
          window.location.href = `${window.location.origin}/${redirectUrl(userId)}`;

          localStorage.clear();
        });
    }

    const timeout = Math.max(0, accessTokenExpiresAt - now) * 0.8;

    if (auth.refreshAccessTokenHandler) {
      clearTimeout(auth.refreshAccessTokenHandler);
    }

    auth.refreshAccessTokenHandler = setTimeout(() => {
      auth
        .refresh({
          data: { email, refreshToken },
        })
        .catch(() => {
          auth
            .logout({
              makeRequest: false,
            })
            .then(() => {
              window.location.href = `${window.location.origin}/${redirectUrl(userId)}`;

              localStorage.clear();
            });
        });

      setRefreshAccessTokenHandler();
    }, timeout);
  }
};

const setUserDataForGA = (user) => {
  if (window.gtag && user.email && !user.email.endsWith('@gaviti.com')) {
    window.gtag('config', import.meta.env.VITE_APP_GA_ID, {
      user_id: user.id,
    });

    if (user.selectedCompany) {
      window.gtag('set', 'user_properties', {
        companyId: user.selectedCompany.id,
      });
    }
  }
};

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    http: axios,
    auth: {
      request(req) {
        const token = localStorage.getItem('GAV_accessToken');
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const companyId = localStorage.getItem('GAV-company-id');
        if (companyId) {
          headers['GAV-company-id'] = companyId;
        }

        this.drivers.http.setHeaders.call(this, req, headers);

        Vue.axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
      },
      response({ request: { responseURL }, data: dataResponse = {} }) {
        if ([this.options.loginData.url, this.options.refreshData.url].includes(responseURL)) {
          const {
            success,
            data: {
              accessToken,
              refreshToken,
              refreshTokenExpiresAt,
              requireOTP,
              otpAuth,
              passwordResetToken,
              requireSSO,
              entryPoint,
            } = {},
          } = dataResponse;

          if (success) {
            if (accessToken) {
              this.token(this.options.tokenDefaultName, accessToken);

              if (requireOTP && this.requireOtp) {
                this.requireOtp(otpAuth);
              }

              if (refreshToken) {
                this.token(this.options.refreshTokenName, refreshToken);
                this.token(this.options.refreshTokenExpiresAtName, refreshTokenExpiresAt);
              }

              return accessToken;
            }

            if (passwordResetToken) {
              Vue.router.push({
                path: `/login/reset/${passwordResetToken}`,
              });
            } else {
              if (requireSSO && !isNil(entryPoint)) {
                window.location.href = entryPoint;
              } else {
                throw new Error();
              }
            }
          } else {
            throw new Error();
          }
        }
      },
    },
    router,
  },
  options: {
    stores: ['storage'],
    tokenDefaultName: 'GAV_accessToken',
    refreshTokenName: 'GAV_refreshToken',
    refreshTokenExpiresAtName: 'GAV_refreshTokenExpiresAt',
    loginData: {
      url: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/login`,
      method: 'POST',
      redirect: false,
    },
    logoutData: {
      enabled: false,
      redirect: false,
    },
    registerData: {
      url: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/register`,
      method: 'POST',
      redirect: false,
    },
    fetchData: {
      url: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2/user/me`,
      method: 'POST',
    },
    refreshData: {
      url: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/refresh`,
      method: 'POST',
      enabled: false,
    },
    parseUserData(user) {
      if (user) {
        setRefreshAccessTokenHandler();

        if (user.idleTimeDisconnect) {
          const idle = new IdleJs({
            idle: user.idleTimeDisconnect,
            onIdle() {
              if (Vue.auth.check()) {
                Vue.auth
                  .logout({
                    makeRequest: false,
                  })
                  .catch(() => {
                    window.location.href = `${window.location.origin}/${redirectUrl(user?.id)}`;
                    localStorage.clear();
                  });
              }
            },
          });

          idle.start();
        }

        if (user.id) {
          setUserDataForGA(user);

          setInterval(() => setUserDataForGA(user), 15 * 60 * 1000);

          if (user.ssoEnabled) {
            store.dispatch('auth/setSecurityPolicy', { [user.id]: user.securityPolicy });
          }
        }

        if (user.selectedCompany) {
          Vue.axios.defaults.headers.common['GAV-company-id'] = user.selectedCompany.id;

          user.selectedCompany.billingType = 'none';

          localStorage.setItem('GAV-company-id', user.selectedCompany.id);

          store.dispatch('settings/findMerchant', { companyId: user.selectedCompany.id });

          if (user.defaultDashboardId) {
            store.dispatch('dashboard/setDefaultDashboardId', { defaultDashboardId: user.defaultDashboardId });

            if (!isNil(user.permissions) && !isEmpty(user.permissions)) {
              const accessControl = new AccessControl(user.permissions);

              store.dispatch('app/setAccessControl', accessControl);
            }
          }
        }
      }

      return user;
    },
  },
});
