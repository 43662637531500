import { cloneDeep, isNil, isEqual, isEmpty, set, get } from 'lodash-es';
import Vue from 'vue';

import {
  companyService,
  authService,
  activityService,
  invoiceService,
  settingsService,
  bankReconciliationService,
} from '@services';
import { MODES } from '@components/Shared/AppDrawer/utils/config';
import { ADAPTERS } from '@enums';
import { i18n } from '@plugins';

import adapterFields from '@/views/Settings/BackOffice/utils/adapterFields';

const { BUCKET_TYPES } = ADAPTERS;

const { VITE_APP_REGION, MODE, VITE_APP_DEBUG_MODE } = import.meta.env;
const isProduction = isEqual(MODE, 'production');
const isDebugMode = !isNil(VITE_APP_DEBUG_MODE) && isEqual(VITE_APP_DEBUG_MODE, 'true');

const needJsonAdapter = ['codat', 'qbo'];

const getBucket = () => {
  const region = isNil(VITE_APP_REGION) || !isEqual(VITE_APP_REGION, 'us') ? 'EU' : 'US';

  return get(BUCKET_TYPES, `${!isProduction ? 'DEV' : isDebugMode ? 'STAGE' : region}.value`, null);
};

export default {
  fetchCompanySettings: async ({ commit }, payload) => {
    try {
      commit('LOADING_STATUS', true);

      const data = await companyService.fetchSettingsByCompanyId(payload);
      const { userCustomerRelations } = data;

      if (userCustomerRelations && !isEmpty(userCustomerRelations)) {
        Object.values(userCustomerRelations).forEach((value) => {
          if (isEmpty(value?.defaultUserId) || isNil(value?.defaultUserId)) {
            Vue.prototype.$showToast(
              'error',
              i18n.t('errorMessages.unassignedUser', {
                ucr: value?.translations?.[i18n.locale || 'en'],
              }),
            );
          }
        });
      }

      commit('FETCH_COMPANY_SETTINGS', data);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  fetchAndUpdateCompanySetting: async ({ commit }, { companyId, name }) => {
    try {
      commit('LOADING_STATUS', true);

      const res = await companyService.fetchSpecificSettingsByCompanyId({ companyId, names: [name] });

      const value = res[name];

      commit('UPDATE_COMPANY_SETTING', { companyId, name, value });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  updateCompanySetting: async ({ commit }, { companyId, name, value }) => {
    try {
      commit('LOADING_STATUS', true);
      await companyService.updateSettings({
        companyId,
        name,
        value,
      });

      commit('UPDATE_COMPANY_SETTING', { companyId, name, value });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('LOADING_STATUS', false);
    }
  },
  insertCompanyUser: async ({ commit }, user) => {
    try {
      const { isNewUser, userId } = await companyService.createUser(user);

      if (isNewUser && !isNil(userId)) {
        user.id = userId;

        commit('ADD_COMPANY_USERS', user);

        return { isNewUser };
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCompanyUser: async ({ commit, dispatch }, user) => {
    try {
      await companyService.updateCompanyUser(user);

      commit('UPDATE_COMPANY_USERS', user);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateUser: async ({ dispatch }, user) => {
    try {
      await companyService.updateUser(user);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCompanyUserSettings: async (_, { companyId, userId, settings }) => {
    try {
      await companyService.updateUserSettings({
        companyId,
        userId,
        settings,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  getCompanyUserSettings: async (_, { companyId }) => {
    try {
      return await companyService.fetchUserHeaders({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  loadCompanyUsers: async ({ commit }, { companyId }) => {
    try {
      let companyUsers = await companyService.fetchUsers({ companyId });

      // TODO - Will change at GS-2726
      companyUsers = companyUsers
        .filter((user) => user.email !== 'support@gaviti.com')
        .sort(({ name: name1 }, { name: name2 }) => name1.toLowerCase().localeCompare(name2.toLowerCase()));

      commit('LOAD_COMPANY_USERS', companyUsers);
    } catch ({ message }) {
      console.error(message);
    }
  },
  performPasswordReset: async () => {
    try {
      await authService.resetPassword();
    } catch ({ message }) {
      console.error(message);
    }
  },
  async showLogs({ dispatch, commit }) {
    await dispatch('app/toggleShowAppDrawer', { show: true, mode: MODES.WIDE, title: 'Logs' }, { root: true });
    commit('SHOW_LOGS');
  },
  hideLogs({ commit }) {
    commit('HIDE_LOGS');
  },
  demoActivities: async ({ dispatch }, _this) => {
    try {
      switch (_this.demoCompany.action) {
        case 'create': {
          await activityService.createDemo({
            companyId: _this.$auth.user().selectedCompany.id,
          });

          break;
        }

        case 'clean': {
          await activityService.cleanDemo({
            companyId: _this.$auth.user().selectedCompany.id,
          });

          break;
        }

        case 'generate': {
          await invoiceService.createDemoClosed({
            companyId: _this.$auth.user().selectedCompany.id,
          });

          break;
        }

        case 'generatePaid': {
          await invoiceService.createDemoPaid({
            companyId: _this.$auth.user().selectedCompany.id,
          });

          break;
        }

        case 'generateBankTransactions': {
          await bankReconciliationService.generateDemo({
            companyId: _this.$auth.user().selectedCompany.id,
          });

          break;
        }

        default:
          throw new TypeError(`The action '${_this.demoCompany.action}' is not supported!`);
      }

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      dispatch(
        'notifications/presentNotification',
        {
          type: 'error',
          visible: true,
          message,
        },
        { root: true },
      );
    }
  },
  getAdaptersList: async (_, companyId) => {
    try {
      return await companyService.fetchAdaptersByCompanyId({ companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateAdapter: async (_, { companyId, name, fields, upsert = false }) => {
    try {
      return await companyService.updateAdapter({
        companyId,
        name,
        fields,
        upsert,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  runAdapterIndex: async (_, { name, token, body, contentType }) => {
    try {
      return await companyService.runAdapterIndex({
        name,
        token,
        body,
        contentType,
      });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  runAdapterMethod: async (_, payload) => {
    try {
      return await companyService.runAdapterMethod(payload);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  createMerchant: async (_, payload) => {
    try {
      return await companyService.createMerchant(payload);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  findMerchant: async ({ commit }, payload) => {
    try {
      const merchant = await companyService.findMerchant(payload);

      commit('SET_MERCHANTS', merchant);
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  checkWorkflowConfigurations: async ({ commit }, payload) => {
    try {
      const configurations = await companyService.checkWorkflowConfigurations(payload);

      commit('SET_WORKFLOW_CONFIGURATIONS_CHECK', configurations);
    } catch ({ message }) {
      console.error(message);
    }
  },
  createAdapter: async ({ dispatch }, { type, companyId }) => {
    const bucket = getBucket();
    const adapterConfig = cloneDeep(adapterFields(companyId)[type]);

    set(adapterConfig, 'bucket.value', bucket);
    set(adapterConfig, 'name.value', companyId);
    set(adapterConfig, 'active.value', true);

    if (needJsonAdapter.includes(type)) {
      const jsonAdapterConfig = cloneDeep(adapterFields(companyId).json);

      set(jsonAdapterConfig, 'bucket.value', bucket);
      set(jsonAdapterConfig, 'name.value', companyId);
      set(jsonAdapterConfig, 'active.value', true);

      if (isEqual(type, 'qbo')) {
        set(jsonAdapterConfig, 'customFieldsMapping.value', {
          customer: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayName' },
                value: { value: 'FullyQualifiedName' },
              },
              {
                key: { value: 'emailsLevel_1' },
                value: { value: 'PrimaryEmailAddr.Address' },
              },
              {
                key: { value: 'phone' },
                value: { value: 'PrimaryPhone.FreeFormNumber' },
              },
            ],
          },
          invoice: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'Id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'DocNumber' },
              },
              {
                key: { value: 'customerId' },
                value: { value: 'CustomerRef.value' },
              },
              {
                key: { value: 'dueDate' },
                value: { value: 'DueDate' },
              },
              {
                key: { value: 'balance' },
                value: { value: 'Balance' },
              },
              {
                key: { value: 'localBalance' },
                value: { value: 'Balance' },
              },
              {
                key: { value: 'totalAmount' },
                value: { value: 'TotalAmt' },
              },
              {
                key: { value: 'localTotalAmount' },
                value: { value: 'TotalAmt' },
              },
              {
                key: { value: 'createDate' },
                value: { value: 'MetaData.CreateTime' },
              },
              {
                key: { value: 'currency' },
                value: { value: 'CurrencyRef.value' },
              },
              {
                key: { value: 'link' },
                value: { value: 'InvoiceLink' },
              },
            ],
          },
        });
        set(jsonAdapterConfig, 'calculatedFields.value', {
          customer: {
            contactName:
              // eslint-disable-next-line no-template-curly-in-string
              "return `${itemRaw['GivenName'] ? itemRaw['GivenName'] : ''} ${itemRaw['FamilyName'] ? itemRaw['FamilyName'] : ''}`.trim();",
          },
        });
      }

      if (isEqual(type, 'codat')) {
        set(jsonAdapterConfig, 'customFieldsMapping.value', {
          customer: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayName' },
                value: { value: 'customerName' },
              },
              {
                key: { value: 'emailsLevel_1' },
                value: { value: 'emailAddress' },
              },
              {
                key: { value: 'phone' },
                value: { value: 'phone' },
              },
            ],
          },
          invoice: {
            mappings: [
              {
                key: { value: 'id' },
                value: { value: 'id' },
              },
              {
                key: { value: 'displayId' },
                value: { value: 'invoiceNumber' },
              },
              {
                key: { value: 'customerId' },
                value: { value: 'customerRef.id' },
              },
              {
                key: { value: 'createDate' },
                value: { value: 'issueDate' },
              },
              {
                key: { value: 'dueDate' },
                value: { value: 'dueDate' },
              },
              {
                key: { value: 'totalAmount' },
                value: { value: 'totalAmount' },
              },
              {
                key: { value: 'balance' },
                value: { value: 'amountDue' },
              },
              {
                key: { value: 'currency' },
                value: { value: 'currency' },
              },
            ],
          },
        });
      }

      const jsonAdapter = await dispatch(
        'backOffice/createAdapterInstance',
        {
          adapterConfig: jsonAdapterConfig,
          editMode: false,
        },
        { root: true },
      );

      await dispatch('updateAdapter', {
        companyId,
        name: jsonAdapter.name,
        fields: jsonAdapter,
        upsert: true,
      });

      set(adapterConfig, 'gavitiJsonAdapterName.value', jsonAdapter.name);
    }

    if (isEqual(type, 'qbo')) {
      set(adapterConfig, 'fetchCredits.value', true);
    }

    if (isEqual(type, 'codat') && (isDebugMode || !isProduction)) {
      set(adapterConfig, 'baseURL.value', 'https://api-uat.codat.io/');
    }

    const adapter = await dispatch(
      'backOffice/createAdapterInstance',
      {
        adapterConfig,
        editMode: false,
      },
      { root: true },
    );

    await dispatch('updateAdapter', {
      companyId,
      name: adapter.name,
      fields: adapter,
      upsert: true,
    });

    return adapter;
  },
  templateFile: async (_, payload) => {
    try {
      return await companyService.getTemplateFile(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  uploadCompanyLogo: async ({ dispatch }, payload) => {
    try {
      await companyService.uploadLogo(payload);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  presentModal: ({ commit }, payload) => {
    commit('SET_MODAL', {
      ...payload,
      open: true,
    });
  },
  dismissModal: ({ commit }) => {
    commit('SET_MODAL', {
      open: false,
      title: undefined,
      contentType: undefined,
    });
  },
  importCustomFields: async ({ dispatch }, file) => {
    try {
      const {
        selectedCompany: {
          id: companyId,
          name: { en: companyName },
        },
      } = Vue.auth.user();

      const formData = new FormData();

      formData.append('companyId', companyId);
      formData.append('companyName', companyName);
      formData.append('file', file);

      await settingsService.importCustomFields(formData);

      await dispatch('fetchCompanySettings', { companyId });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateCustomField: async ({ dispatch }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await settingsService.updateCustomField({
        companyId,
        ...payload,
      });

      await dispatch('fetchCompanySettings', { companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  importInternalPersonas: async ({ dispatch }, file) => {
    try {
      const {
        selectedCompany: {
          id: companyId,
          name: { en: companyName },
        },
      } = Vue.auth.user();

      const formData = new FormData();

      formData.append('companyId', companyId);
      formData.append('companyName', companyName);
      formData.append('file', file);

      await settingsService.importInternalPersonas(formData);

      await dispatch('fetchCompanySettings', { companyId });

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateInternalPersonas: async ({ dispatch }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await settingsService.updateInternalPersonas({
        companyId,
        ...payload,
      });

      await dispatch('fetchCompanySettings', { companyId });
    } catch ({ message }) {
      console.error(message);
    }
  },
  getPlaidLinkToken: async ({}) => {
    try {
      return companyService.plaidLinkToken({ companyId: Vue.auth.user().selectedCompany.id });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  plaidConnect: async ({}, payload) => {
    try {
      return companyService.plaidConnect({ companyId: Vue.auth.user().selectedCompany.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  getPlaidReconnectLinkToken: async ({}, payload) => {
    try {
      return companyService.plaidReconnectLinkToken({ companyId: Vue.auth.user().selectedCompany.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
  plaidReconnect: async ({}, payload) => {
    try {
      return companyService.plaidReconnect({ companyId: Vue.auth.user().selectedCompany.id, ...payload });
    } catch ({ message }) {
      console.error(message);

      throw new Error(message);
    }
  },
};
