export default {
  isLoading: ({ isLoading }) => isLoading,
  invoices: ({ invoices }) => invoices,
  stats: ({ stats }) => stats,
  totalARBalance: ({ totalARBalance }) => totalARBalance,
  defaultHeaders: () => [
    'workflowEnabled',
    'displayId',
    'customer.displayName',
    'status',
    'dueDate',
    'daysOverdue',
    'localBalance',
    'localTotalAmount',
  ],
  selected: ({ selected }) => selected,
};
