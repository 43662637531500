export class BankReconciliation {
  constructor(objInit) {
    this.accountId = objInit.accountId;
    this.id = objInit.id;
    this.credit = objInit.credit;
    this.date = objInit.date;
    this.debit = objInit.debit;
    this.description = objInit.description;
    this.balance = objInit.balance;
    this.status = objInit.status;
    this.allocations = objInit.allocations;
    this.comment = objInit.comment;
    this.currency = objInit.currency;
    this.isSuggestionsDismissed = objInit.isSuggestionsDismissed;
  }
}
