import {
  format,
  parseISO,
  differenceInMonths,
  addMonths,
  subDays,
  getWeekOfMonth,
  startOfWeek,
  addWeeks,
  addYears,
  subMonths,
  isAfter,
} from 'date-fns';
import { sortBy, isEqual, isString, sumBy, isNil, uniq, isEmpty, sum, keyBy, get, range } from 'lodash-es';
import colors from 'vuetify/lib/util/colors';
import { es, he } from 'date-fns/locale';
import Vue from 'vue';

import { formatCurrency, formatCurrencyBigNumbers } from '@utils/money';
import { formatBigNumbers } from '@utils/numbers';
import { i18n, vuetify } from '@plugins';
import { AGING_BUCKETS } from '@enums';

import actions from './actions';

const { UPCOMING, OVERDUE, DAY_FROM, UPCOMING_COLOR, OVERDUE_COLORS } = AGING_BUCKETS;

const locales = { es, he };
const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();
const FUTURE_MONTHS = 3;
const PAYMENT_GATEWAY_MONTHS = 3;
const LATE_PAYMENTS_MONTHS = 3;
const {
  primary,
  'risk-0': risk0,
  'risk-1': risk1,
  'risk-2': risk2,
  'risk-3': risk3,
  'risk-4': risk4,
  'light-primary': lightPrimary,
  'blue-purple': bluePurple,
  'light-azure': lightAzure,
  teal,
  azure,
  secondary,
} = vuetify.framework.theme.themes.light;

const chartOptions = {
  noData: {
    text: 'No records found',
    style: {
      fontSize: '1.25rem',
      fontFamily: 'Roboto, Heebo, sans-serif',
      color: colors.grey.darken1,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 0.05,
      inverseColors: true,
      type: 'vertical',
      opacityFrom: 0.95,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
};

const animations = {
  enabled: true,
  easing: 'easeinout',
  speed: 350,
  dynamicAnimation: {
    enabled: true,
    speed: 350,
  },
};

const demoCompanyData = {
  paymentsForecast: {
    pastData: [6800000, 4500000, 0, 0, 0],
    futureData: [0, 900000, 5700000, 4200000, 1100000],
  },
  weeklyPaymentsForecast: {
    pastData: [1800000, 1300000, 800000, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    futureData: [0, 0, 350000, 1700000, 1100000, 1400000, 1300000, 950000, 850000, 1200000, 1000000, 1100000],
  },
  totalARCompareTrends: {
    totalAR: 9,
    totalOverdue: -12,
  },
  overdueCustomersCompareTrend: -14,
  outstandingInvoicesCompareTrends: {
    outstanding: 10,
    overdue: -12,
  },
  dso: [67, 69, 73, 77, 85, 92, 93],
  agingBucketsCompareTrends: [9, -10, -11, -9, -10],
};

const getDemoDSOChart = () => {
  const dso = [];

  for (let i = 1; i <= 7; i += 1) {
    dso.push({
      date: new Date(currentYear, currentMonth - i),
      value: demoCompanyData.dso[i - 1],
    });
  }

  return dso;
};

const getPaymentsForecastChartCategories = () => {
  const categories = [];

  for (let i = -1; i < FUTURE_MONTHS; i += 1) {
    categories.push(new Date(currentYear, currentMonth + i));
  }

  categories.push('Future');

  return categories;
};

const getPaymentGatewayChartCategories = () => {
  const categories = [];

  for (let i = -PAYMENT_GATEWAY_MONTHS; i <= 0; i += 1) {
    categories.push(new Date(currentYear, currentMonth + i));
  }

  return categories;
};

const getPaymentsHistoryChartCategories = () => {
  const categories = [];

  for (let i = -LATE_PAYMENTS_MONTHS; i <= 0; i += 1) {
    categories.push(new Date(currentYear, currentMonth + i));
  }

  return categories;
};

const getWeeklyPaymentsForecastDemoChartCategories = () => {
  const categories = [];

  for (let i = -2; i <= 8; i += 1) {
    const date = addWeeks(new Date(), i);

    categories.push(startOfWeek(date, { weekStartsOn: 1 }).getTime());
  }

  categories.push('Future');

  return categories;
};

const initializePaymentsForecastChart = (categories) => {
  const data = {};

  for (const category of categories) {
    data[category] = {
      past: 0,
      future: 0,
      promiseToPay: 0,
    };
  }

  return data;
};

const initializePaymentGatewayChart = (categories) => {
  const data = {};

  for (const category of categories) {
    data[category] = {
      count: 0,
      amount: 0,
    };
  }

  return data;
};

const initializePaymentsHistoryChart = (categories) => {
  const data = {};

  for (const category of categories) {
    data[category] = {
      onTime: {
        count: 0,
        amount: 0,
      },
      overdue: {
        count: 0,
        amount: 0,
      },
    };
  }

  return data;
};

const findPaymentsForecastChartCategory = ({ array, category }) =>
  array.find(
    ({ dateParts }) =>
      !isNil(dateParts) &&
      isEqual(category.getFullYear(), dateParts.year) &&
      isEqual(category.getMonth(), dateParts.month - 1),
  );

const findCollectionRateChartCategory = ({ array, month }) =>
  array.find(({ dateParts }) => !isNil(dateParts) && isEqual(month, dateParts.month - 1));

const findPaymentsHistoryChartCategory = ({ array, category }) =>
  array.find(
    ({ dateParts }) =>
      !isNil(dateParts) &&
      isEqual(category.getFullYear(), dateParts.year) &&
      isEqual(category.getMonth(), dateParts.month - 1),
  );

const removeEmptyPaymentsForecastChartCategories = (data) => {
  for (const category in data) {
    if (data[category].past <= 0 && data[category].future <= 0 && data[category].promiseToPay <= 0) {
      delete data[category];
    }
  }

  return data;
};

const getPaymentsForecastChartData = ({ past, future, categories }) => {
  const data = initializePaymentsForecastChart(categories);

  for (const category of categories) {
    if (isEqual(category, 'Future')) {
      data[category].future = sumBy(
        future.filter(
          ({ dateParts: { year, month } }) => isEqual(year, currentYear) && month > currentMonth + FUTURE_MONTHS,
        ),
        'sum',
      );
      data[category].promiseToPay = sumBy(
        future.filter(
          ({ dateParts: { year, month } }) => isEqual(year, currentYear) && month > currentMonth + FUTURE_MONTHS,
        ),
        'promiseToPay',
      );
    } else {
      const categoryPast = findPaymentsForecastChartCategory({ array: past, category });
      const categoryFuture = findPaymentsForecastChartCategory({ array: future, category });

      if (categoryPast) {
        data[category].past = categoryPast.sum;
      }

      if (categoryFuture) {
        data[category].future = categoryFuture.sum;
        data[category].promiseToPay = categoryFuture.promiseToPay;
      }
    }
  }

  return removeEmptyPaymentsForecastChartCategories(data);
};

const getCollectionRateChartData = ({ past, current }) => {
  const data = {
    past: [],
    current: [],
  };

  for (let month = 0; month < 12; month += 1) {
    const categoryPast = findCollectionRateChartCategory({ array: past, month });
    const categoryCurrent = findCollectionRateChartCategory({ array: current, month });

    if (categoryPast) {
      data.past.push(categoryPast.value);
    } else {
      data.past.push(0);
    }

    if (categoryCurrent && month <= new Date().getMonth()) {
      data.current.push(categoryCurrent.value);
    } else if (month <= new Date().getMonth()) {
      data.current.push(0);
    }
  }

  return data;
};

const getPaymentGatewayChartData = ({ chart, categories }) => {
  const data = initializePaymentGatewayChart(categories);

  for (const category of categories) {
    const categoryData = findPaymentsForecastChartCategory({ array: chart, category });

    if (categoryData) {
      data[category].count = categoryData.count;
      data[category].amount = categoryData.amount;
    }
  }

  return data;
};

const getPaymentsHistoryChartData = ({ chart, categories }) => {
  const data = initializePaymentsHistoryChart(categories);
  const filteredCategories = [];

  for (const category of categories) {
    const categoryData = findPaymentsHistoryChartCategory({ array: chart, category });

    if (categoryData) {
      data[category].onTime = categoryData.onTime;
      data[category].overdue = categoryData.overdue;

      filteredCategories.push(category);
    }
  }

  const filteredSeries = Object.values(
    Object.fromEntries(
      Object.entries(data).filter(
        ([, { onTime, overdue }]) => onTime.count > 0 || onTime.amount > 0 || overdue.count > 0 || overdue.amount > 0,
      ),
    ),
  );

  return {
    series: filteredSeries,
    categories: filteredCategories,
  };
};

const getMetricPercentage = ({ currentValue, pastValue }) => {
  try {
    if (pastValue > 0) {
      const total = currentValue - pastValue;

      return (total / pastValue) * 100;
    }

    return 0;
  } catch ({ message }) {
    console.error(message);
  }
};

const getTrend = ({ name, value, key }) => {
  const roundValue = Math.round(value);

  if (isEqual(roundValue, 0)) {
    return;
  }

  const good = {
    color: 'primary--text',
    key,
    value,
  };
  const bad = {
    color: 'red--text text--lighten-2',
    key,
    value,
  };

  switch (name) {
    case 'greaterThan':
      return value >= 0
        ? {
            ...good,
            text: i18n.t('dashboard.chartCentral.increase', {
              value: Math.abs(roundValue),
            }),
          }
        : {
            ...bad,
            text: i18n.t('dashboard.chartCentral.decrease', {
              value: Math.abs(roundValue),
            }),
          };
    case 'lessThan':
      return value <= 0
        ? {
            ...good,
            text: i18n.t('dashboard.chartCentral.decrease', {
              value: Math.abs(roundValue),
            }),
          }
        : {
            ...bad,
            text: i18n.t('dashboard.chartCentral.increase', {
              value: Math.abs(roundValue),
            }),
          };
    default:
      return {};
  }
};

const getAgingBucketsCompareChartSeries = ({ buckets }) => {
  const series = [];

  for (const name in buckets) {
    if (buckets.hasOwnProperty(name)) {
      const { data } = buckets[name];

      series.push({
        name,
        data,
      });
    }
  }

  return series;
};

const getAgingBucketsCompareChartTrends = ({ buckets }) => {
  const trends = [];

  let index = 0;

  for (const name in buckets) {
    if (buckets.hasOwnProperty(name)) {
      const {
        type,
        data: [pastValue, currentValue],
      } = buckets[name];

      trends.push(
        getTrend({
          name: isEqual(type, UPCOMING) ? 'greaterThan' : 'lessThan',
          key: name,
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? demoCompanyData.agingBucketsCompareTrends[index]
            : getMetricPercentage({
                pastValue,
                currentValue,
              }),
        }),
      );

      index += 1;
    }
  }

  return trends.sort((a, b) => Math.abs(b.value) - Math.abs(a.value)).slice(0, 2);
};

const getAgingBucketsCompareChartColors = ({ buckets }) => {
  if (buckets) {
    const upcomingBuckets = Object.values(buckets).filter(({ type }) => isEqual(type, UPCOMING)).length;
    const overdueBuckets = Object.values(buckets).filter(({ type }) => isEqual(type, OVERDUE)).length;
    const colors = Array(upcomingBuckets).fill(UPCOMING_COLOR);

    for (let i = 0; i < overdueBuckets; i += 1) {
      colors.push(OVERDUE_COLORS[i]);
    }

    return colors;
  }

  return [];
};

const getMonthlyPaymentsForecastChart = ({ past, future, uiShowPaymentForecasting }) => {
  let categories = getPaymentsForecastChartCategories();
  let pastData = [];
  let futureData = [];
  let promiseToPayData = [];

  if (isEqual(Vue.auth.user().selectedCompany.type, 'demo') || !uiShowPaymentForecasting) {
    const {
      paymentsForecast: { pastData: demoPastData, futureData: demoFutureData },
    } = demoCompanyData;

    pastData = demoPastData;
    futureData = demoFutureData;
  } else {
    const data = getPaymentsForecastChartData({ past, future, categories });

    categories = categories.filter((category) => Object.keys(data).includes(category.toString()));
    pastData = Object.values(data).map(({ past }) => past);
    futureData = Object.values(data).map(({ future }) => future);
    promiseToPayData = Object.values(data).map(({ promiseToPay }) => promiseToPay);
  }

  return {
    categories,
    pastData,
    futureData,
    promiseToPayData,
  };
};

const getWeeklyPaymentsForecastChart = ({ past, future }) => {
  let categories = [];
  let pastData = [];
  let futureData = [];
  let promiseToPayData = [];

  if (isEqual(Vue.auth.user().selectedCompany.type, 'demo')) {
    const {
      weeklyPaymentsForecast: { pastData: demoPastData, futureData: demoFutureData },
    } = demoCompanyData;

    categories = getWeeklyPaymentsForecastDemoChartCategories();
    pastData = demoPastData;
    futureData = demoFutureData;
  } else {
    categories = [...past.map(({ date }) => date), ...future.map(({ date }) => date)];

    categories = uniq(categories);

    const data = initializePaymentsForecastChart(categories);

    for (const category of categories) {
      const pastSum = past.find(({ date }) => isEqual(date, category));
      const futureSum = future.find(({ date }) => isEqual(date, category));

      if (pastSum) {
        data[category].past = pastSum.sum;
      }

      if (futureSum) {
        data[category].future = futureSum.sum;
        data[category].promiseToPay = futureSum.promiseToPay;
      }
    }

    const cleanData = removeEmptyPaymentsForecastChartCategories(data);

    categories = categories.filter(
      (category) => !isNil(category) && Object.keys(cleanData).includes(category.toString()),
    );
    pastData = Object.values(cleanData).map(({ past }) => past);
    futureData = Object.values(cleanData).map(({ future }) => future);
    promiseToPayData = Object.values(cleanData).map(({ promiseToPay }) => promiseToPay);
  }

  return {
    categories,
    pastData,
    futureData,
    promiseToPayData,
  };
};

export default {
  isChartLoading: ({ isChartLoading }) => isChartLoading,
  invoicesChart: ({ invoicesChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;
    const labelFormat = (value) => {
      if (!isEqual(value, 'Older') && isString(value)) {
        return format(new Date(`${value}-15`), 'MMM yy', {
          locale: locales[i18n.locale],
        });
      }

      if (isEqual(value, 'Older')) {
        return i18n.t('invoice.older');
      }
    };

    const fullCurrentData = [];

    let currentData = [];
    let pastData = [];
    const categories = [];

    for (const {
      dateParts: { year, month },
      sum,
      count,
    } of invoicesChart) {
      if ((isEqual(year, currentYear - 1) && month - 1 > currentMonth) || year >= currentYear) {
        currentData.push({
          x: `${year}-${String(month).padStart(2, '0')}`,
          y: sum,
          z: count,
          range: [Date.UTC(year, month - 1, 15), Date.UTC(year, month, 15) - 1],
        });
      } else {
        pastData.push({
          y: sum,
          z: count,
        });
      }
    }

    currentData = sortBy(currentData, 'x');

    if (currentData.length) {
      const startDate = parseISO(currentData[0].x.concat('-15'));
      const endDate = parseISO(currentData[currentData.length - 1].x.concat('-15'));
      const monthDifference = differenceInMonths(endDate, startDate);

      for (let i = 0; i <= monthDifference; i += 1) {
        fullCurrentData[i] = {
          x: format(addMonths(startDate, i), 'yyyy-MM'),
          y: null,
          z: null,
          range: null,
        };

        for (let j = 0; j < currentData.length; j += 1) {
          if (isEqual(currentData[j].x, fullCurrentData[i].x)) {
            Object.assign(fullCurrentData[i], currentData[j]);
          }
        }
      }

      fullCurrentData.forEach(({ x }) => categories.push(x));
    }

    if (pastData.length) {
      pastData = {
        x: 'Older',
        y: sumBy(pastData, 'y'),
        z: sumBy(pastData, 'z'),
        range: [0, Date.UTC(currentYear - 1, currentMonth + 1, 1) - 1],
      };

      fullCurrentData.unshift(pastData);
      categories.unshift(pastData.x);
    }

    return {
      type: 'bar',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: true,
          x: {
            formatter: (value) => labelFormat(value),
          },
          y: {
            formatter: (val) => formatCurrency(val, currency),
          },
          z: {
            formatter: (val) => val,
            title: i18n.t('nav.invoices'),
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          name: 'Sum',
          data: fullCurrentData,
        },
      ],
    };
  },
  customersChart: ({ customersChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const {
      settings: { companyUsers },
    } = rootGetters;

    const { currency } = Vue.auth.user().selectedCompany;
    const dataOverdue = [];
    const dataUpcoming = [];
    const categories = [];

    let { all, upcoming } = customersChart;
    let user = {};

    all = sortBy(all, '_id');
    upcoming = sortBy(upcoming, '_id');

    if (all && upcoming) {
      for (let i = 0; i < all.length; i += 1) {
        user = companyUsers.find(({ id }) => isEqual(id, all[i]._id));

        const name = isNil(user) ? i18n.t('dashboard.charts.customer.defaultUser') : user.name;

        dataUpcoming.push(upcoming[i].sum);
        dataOverdue.push(all[i].sum - upcoming[i].sum);
        categories.push(isNil(all[i]._id) ? `[${i18n.t('dashboard.charts.customer.defaultUser')}]` : name);
      }
    }

    return {
      type: 'bar',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'bottom',
          markers: {
            radius: 12,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [primary, colors.red.lighten2],
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (val) => formatCurrency(val, currency),
          },
          fillSeriesColor: true,
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.charts.customer.upcoming'),
          data: dataUpcoming,
        },
        {
          name: i18n.t('dashboard.charts.customer.overdue'),
          data: dataOverdue,
        },
      ],
    };
  },
  bankChart: ({ bankChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;

    const { currency } = Vue.auth.user().selectedCompany;
    const dataDebit = [];
    const dataCredit = [];
    const categories = [];

    for (const data of bankChart) {
      const { localDebit, localCredit, dateParts } = data;

      const sixMonthsAgo = subMonths(new Date(), 6);

      const date = new Date(dateParts.year, dateParts.month - 1);

      if (isAfter(date, sixMonthsAgo) || isEqual(Vue.auth.user().selectedCompany.type, 'demo')) {
        const formattedDate = format(date, 'MMMM yy', {
          locale: locales[i18n.locale],
        });

        const existingIndex = categories.indexOf(formattedDate);

        if (existingIndex !== -1) {
          dataDebit[existingIndex] += localDebit;
          dataCredit[existingIndex] += localCredit;
        } else {
          categories.push(formattedDate);
          dataDebit.push(localDebit);
          dataCredit.push(localCredit);
        }
      }
    }

    return {
      type: 'bar',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'bottom',
          markers: {
            radius: 12,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [colors.red.lighten2, primary],
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (val) => formatCurrency(val, currency),
          },
          fillSeriesColor: true,
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          categories,
        },
      },
      series: [
        {
          name: i18n.t('bankReconciliation.chart.debit'),
          data: dataDebit,
        },
        {
          name: i18n.t('bankReconciliation.chart.credit'),
          data: dataCredit,
        },
      ],
    };
  },
  paymentsForecastChart: ({ paymentsForecastChart: { past, future } }, _, rootGetters) => {
    const {
      app: { darkMode },
      settings: {
        companySettings: { uiShowPaymentForecasting },
      },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const labelFormat = (date) => {
      if (!isEqual(date, 'Future')) {
        return format(date, 'MMM yy', {
          locale: locales[i18n.locale],
        });
      }

      return i18n.t('dashboard.charts.paymentsForecast.future');
    };

    const { categories, pastData, futureData, promiseToPayData } = getMonthlyPaymentsForecastChart({
      past,
      future,
      uiShowPaymentForecasting,
    });

    return {
      type: 'bar',
      title: i18n.t('dashboard.payments'),
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          stacked: true,
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        legend: {
          position: 'bottom',
          markers: {
            radius: 12,
          },
          itemMargin: {
            vertical: 5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            barHeight: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
            hideOverflowingLabels: false,
          },
          style: {
            fontSize: '11px',
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [primary, colors.lightBlue.darken2, colors.blue.lighten1],
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: true,
          y: {
            formatter: (val) => formatCurrency(val, currency, false),
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.paid'),
          data: pastData,
        },
        {
          name: i18n.t('dashboard.promiseToPay'),
          data: promiseToPayData,
        },
        {
          name: i18n.t('dashboard.projected'),
          data: futureData,
        },
      ],
    };
  },
  outstandingInvoicesChart: ({ overdueInvoices, outstandingInvoices }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const upcomingInvoices = outstandingInvoices - overdueInvoices;

    const { getOutstandingInvoicesChartFilter, getOutstandingInvoicesChartDueDateFilter } = actions;

    const series = [overdueInvoices, upcomingInvoices];

    return {
      type: 'donut',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
        },
        labels: [
          i18n.t('dashboard.charts.outstandingInvoices.overdue'),
          i18n.t('dashboard.charts.outstandingInvoices.upcoming'),
        ],
        colors: [colors.red.lighten1, primary],
        dataLabels: {
          formatter: (val) => `${Math.round(val)}%`,
          dropShadow: {
            enabled: false,
          },
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
        },
        grid: {
          padding: {
            top: 10,
            bottom: 5,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 600,
                  fontSize: '40',
                  color: colors.orange.lighten3,
                  formatter: () => formatBigNumbers(sum(series)),
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: () => Number(sum(series)).toLocaleString(),
                },
              },
            },
          },
        },
      },
      series,
      getFilterName: getOutstandingInvoicesChartFilter,
      getDueDateFilter: getOutstandingInvoicesChartDueDateFilter,
      path: '/li/invoices',
    };
  },
  outstandingInvoicesAmountChart: ({ upcomingOverdueAging }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    let upcomingSum = 0;
    let overdueSum = 0;

    if (!isEmpty(upcomingOverdueAging) && !isNil(upcomingOverdueAging.upcoming)) {
      upcomingSum = upcomingOverdueAging.upcoming;
    }

    if (!isEmpty(upcomingOverdueAging) && !isNil(upcomingOverdueAging.overdue)) {
      overdueSum = upcomingOverdueAging.overdue;
    }

    const { getOutstandingInvoicesChartFilter, getOutstandingInvoicesChartDueDateFilter } = actions;

    const series = [overdueSum, upcomingSum];

    return {
      type: 'donut',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
        },
        labels: [
          i18n.t('dashboard.charts.outstandingInvoices.overdue'),
          i18n.t('dashboard.charts.outstandingInvoices.upcoming'),
        ],
        colors: [colors.red.lighten1, primary],
        dataLabels: {
          formatter: (val) => `${Math.round(val)}%`,
          dropShadow: {
            enabled: false,
          },
        },
        tooltip: {
          theme: darkMode ? 'dark' : 'light',
          fillSeriesColor: false,
          y: {
            formatter: (val) => formatCurrency(val, currency),
          },
        },
        grid: {
          padding: {
            top: 10,
            bottom: 5,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 600,
                  fontSize: '40',
                  color: darkMode ? 'white' : teal,
                  formatter: () => formatCurrencyBigNumbers(sum(series), currency),
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: () => formatCurrencyBigNumbers(sum(series), currency),
                },
              },
            },
          },
        },
      },
      series,
      getFilterName: getOutstandingInvoicesChartFilter,
      getDueDateFilter: getOutstandingInvoicesChartDueDateFilter,
      path: '/li/invoices',
    };
  },
  creditLimitUsage: ({ creditLimitUsage }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const addTotalUsageText = (chartContext, creditLimit) => {
      const chartElement = chartContext.el;
      const chartHeight = chartElement.offsetHeight;

      const textElement = document.createElement('div');
      textElement.className = 'chart-additional-text';
      textElement.style.top = `${chartHeight - 10}px`;
      textElement.innerHTML = i18n.t('dashboard.charts.creditLimitUsage.totalCredit', {
        amount: formatCurrency(creditLimit, currency),
      });

      chartElement.appendChild(textElement);
    };

    if (isNil(creditLimitUsage?.creditLimit)) {
      creditLimitUsage = {
        creditLimit: 20000,
        totalAr: 1000,
      };
    }

    const { creditLimit, totalAr = 0 } = creditLimitUsage;
    const series = [creditLimit - totalAr, totalAr];

    return {
      type: 'donut',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
            mounted(chartContext) {
              addTotalUsageText(chartContext, creditLimit);
            },
            updated(chartContext) {
              addTotalUsageText(chartContext, creditLimit);
            },
          },
        },
        labels: [
          i18n.t('dashboard.charts.creditLimitUsage.availableCredit'),
          i18n.t('dashboard.charts.creditLimitUsage.creditDebt'),
        ],
        colors: creditLimit ? [colors.grey.lighten2, primary] : [colors.grey.lighten2],
        states: {
          hover: {
            filter: {
              type: creditLimit ? 'darken' : 'none',
              value: 0.85,
            },
          },
        },
        stroke: {
          show: Boolean(creditLimit),
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: creditLimit,
          theme: darkMode ? 'dark' : 'light',
          fillSeriesColor: false,
          y: {
            formatter: (val) => formatCurrency(val, currency),
          },
        },
        legend: {
          position: 'bottom',
        },
        grid: {
          padding: {
            top: 35,
            bottom: 50,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '150%',
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  offsetY: 15,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 600,
                  fontSize: '40',
                  color: darkMode ? 'white' : 'black',
                  formatter: () => (isEqual(creditLimit, 0) ? 'N/A' : `${Math.round((totalAr / creditLimit) * 100)}%`),
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: () => (isEqual(creditLimit, 0) ? 'N/A' : `${Math.round((totalAr / creditLimit) * 100)}%`),
                },
              },
            },
          },
        },
      },
      series,
    };
  },
  customersRiskChart: ({ customersRiskChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;

    const { getCustomersRiskChartFilterValue, getCustomersRiskChartTrackingEvent } = actions;

    const risksConfig = [
      {
        label: 'B',
        color: risk1,
        index: '1',
      },
      {
        label: 'C',
        color: risk2,
        index: '2',
      },
      {
        label: 'D',
        color: risk3,
        index: '3',
      },
    ];

    const fRiskCount = sumBy(
      customersRiskChart.filter(({ risk }) => risk >= 4),
      'count',
    );

    const aRiskCount = sumBy(
      customersRiskChart.filter(({ risk }) => risk <= 0),
      'count',
    );

    customersRiskChart = keyBy(customersRiskChart, 'risk');

    customersRiskChart = risksConfig.map((config) => {
      const risk = customersRiskChart[config.index];

      if (!isNil(risk)) {
        return {
          ...config,
          ...risk,
        };
      }

      return {
        ...config,
        count: 0,
      };
    });

    customersRiskChart.unshift({
      label: 'A',
      color: risk0,
      count: aRiskCount,
    });

    customersRiskChart.push({
      label: 'F',
      color: risk4,
      count: fRiskCount,
    });

    const series = customersRiskChart.map(({ count }) => count);

    return {
      type: 'donut',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
        },
        labels: customersRiskChart.map(({ label }) => label),
        colors: customersRiskChart.map(({ color }) => color),
        dataLabels: {
          formatter: (val) => `${Math.round(val)}%`,
          dropShadow: {
            enabled: false,
          },
        },
        tooltip: {
          theme: darkMode ? 'dark' : 'light',
          fillSeriesColor: false,
          y: {
            formatter: (val) => `${val} ${i18n.t('customer.customers').toLowerCase()}`,
          },
        },
        grid: {
          padding: {
            top: 10,
            bottom: 5,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 5,
                },
                value: {
                  show: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 400,
                  fontSize: '14',
                  color: darkMode ? 'white' : teal,
                  offsetY: 10,
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 700,
                  fontSize: '40',
                  color: darkMode ? 'white' : teal,
                  label: formatBigNumbers(sum(series)),
                  formatter: () => i18n.t('customer.customers'),
                },
              },
            },
          },
        },
      },
      series,
      getFilterName: () => 'risk',
      getValueFilter: getCustomersRiskChartFilterValue,
      getTrackingEvent: getCustomersRiskChartTrackingEvent,
      path: '/li/customers',
    };
  },
  agingBucketsChart: ({ agingBucketsChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const { getAgingBucketsChartFilter, getAgingBucketsChartDueDateFilter } = actions;

    const upcomingBuckets = sortBy(
      agingBucketsChart.filter(({ type }) => isEqual(type, UPCOMING)),
      DAY_FROM,
    );
    const overdueBuckets = sortBy(
      agingBucketsChart.filter(({ type }) => isEqual(type, OVERDUE)),
      DAY_FROM,
    );

    const categories = [];
    const totalArData = [];
    const promiseToPayData = [];
    const bucketsColors = [];
    const dueDays = [];

    for (const { dayFrom, dayTo, type, localBalance, promiseToPay } of upcomingBuckets) {
      categories.push(i18n.t('dashboard.charts.totalAR.upcoming'));
      totalArData.push(localBalance - promiseToPay);
      promiseToPayData.push(promiseToPay);
      bucketsColors.push(UPCOMING_COLOR);
      dueDays.push({ dayFrom, dayTo, type });
    }

    for (const [index, { dayFrom, dayTo, type, localBalance, promiseToPay }] of overdueBuckets.entries()) {
      categories.push(`${dayFrom}${isNil(dayTo) ? '+' : `-${dayTo}`}`);
      totalArData.push(localBalance - promiseToPay);
      promiseToPayData.push(promiseToPay);
      bucketsColors.push(OVERDUE_COLORS[index]);
      dueDays.push({ dayFrom, dayTo, type });
    }

    const agingBucketsSeries = [
      {
        name: i18n.t('dashboard.totalAR'),
        data: totalArData.every((value) => isEqual(value, 0)) ? [] : totalArData,
      },
      {
        name: i18n.t('dashboard.promiseToPay'),
        data: promiseToPayData.every((value) => isEqual(value, 0)) ? [] : promiseToPayData,
      },
    ];

    return {
      type: 'bar',
      title: i18n.t('dashboard.agingBuckets'),
      dueDays,
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          stacked: true,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            barHeight: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
            hideOverflowingLabels: false,
          },
          style: {
            fontSize: '11px',
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [({ dataPointIndex }) => bucketsColors[dataPointIndex], colors.lightBlue.darken2],
        tooltip: {
          theme: 'dark',
          fillSeriesColor: true,
          custom: ({ series, dataPointIndex, w }) => {
            const promiseToPay = series[1][dataPointIndex];
            const open = series[0][dataPointIndex];

            return `
                            <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                ${w.globals.labels[dataPointIndex]}
                            </div>

                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold;">
                                    <div class="apexcharts-tooltip-y-group px-1">
                                        <span class="apexcharts-tooltip-text-value">
                                            <span>${i18n.t('dashboard.promiseToPay')}: ${formatCurrency(
                                              promiseToPay,
                                              currency,
                                            )}</span>

                                            <br />

                                            <span>${i18n.t('dashboard.open')}: ${formatCurrency(open, currency)}</span>

                                            <br />

                                            <span>${i18n.t('dashboard.total')}: ${formatCurrency(
                                              open + promiseToPay,
                                              currency,
                                            )}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        `;
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          categories,
        },
      },
      series: agingBucketsSeries,
      getFilterName: getAgingBucketsChartFilter,
      getDueDateFilter: getAgingBucketsChartDueDateFilter,
      path: '/li/invoices',
    };
  },
  paymentGatewayChart: ({ paymentGatewayChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const labelFormat = (date) => {
      if (date) {
        return format(date, 'MMM yy', {
          locale: locales[i18n.locale],
        });
      }
    };

    const categories = getPaymentGatewayChartCategories();
    const series = Object.values(getPaymentGatewayChartData({ chart: paymentGatewayChart, categories }));

    return {
      type: 'line',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        colors: [primary],
        stroke: {
          width: [2],
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
          custom: ({ dataPointIndex }) => {
            const amount = series[dataPointIndex].amount;
            const count = series[dataPointIndex].count;

            return `
                            <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                ${labelFormat(categories[dataPointIndex])}
                            </div>

                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold;">
                                    <div class="apexcharts-tooltip-y-group px-1">
                                        <span class="apexcharts-tooltip-text-value">
                                            <span>${i18n.t(
                                              'dashboard.help.paymentGatewayAmount',
                                            )}: ${formatCurrencyBigNumbers(amount, currency)}</span>

                                            <br />

                                            <span>${count} ${i18n.t('invoice.transactions').toLowerCase()}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        `;
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          data: series.map(({ amount }) => amount),
        },
      ],
    };
  },
  collectionRateCompareChart: ({ collectionRateCompareChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;

    const labelFormat = (month) => {
      if (!isNil(month)) {
        return format(new Date(2000, month, 1), 'MMM', {
          locale: locales[i18n.locale],
        });
      }
    };

    const { past, current } = getCollectionRateChartData(collectionRateCompareChart);
    const series = [
      {
        name: addYears(new Date(), -1).getFullYear(),
        data: past,
      },
      {
        name: new Date().getFullYear(),
        data: current,
      },
    ];
    const seriesColors = [primary, colors.lightBlue.darken2];

    return {
      type: 'line',
      options: {
        ...chartOptions,
        annotations: {
          xaxis: [
            {
              x: labelFormat(new Date().getMonth()),
              borderColor: colors.grey.lighten1,
            },
          ],
        },
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        colors: seriesColors,
        stroke: {
          width: [2, 2],
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
          custom: ({ dataPointIndex }) => {
            return `
                            <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                              ${labelFormat(dataPointIndex)}
                            </div>

                            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold;">
                                    <div class="apexcharts-tooltip-y-group px-1">
                                        <span class="apexcharts-tooltip-text-value">
                                            <span><span style="color: ${seriesColors[0]}">${
                                              series[0].name
                                            } </span> ${i18n.t('dashboard.collectionRate').toLowerCase()}: ${
                                              series[0].data[dataPointIndex]
                                            }% </span>

                                            <br />

                                            <span style="display: ${
                                              series[1].data[dataPointIndex] ? 'block' : 'none'
                                            }"><span style="color: ${seriesColors[1]}">${series[1].name} </span> ${i18n
                                              .t('dashboard.collectionRate')
                                              .toLowerCase()}: ${series[1].data[dataPointIndex]}% </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        `;
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => `${val}%`,
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories: Array.from(Array(12).keys()),
        },
      },
      series,
    };
  },
  paymentsHistoryChart: ({ paymentsHistoryChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const labelFormat = (date) => {
      if (date) {
        return format(date, 'MMM yy', {
          locale: locales[i18n.locale],
        });
      }
    };

    const { series, categories } = getPaymentsHistoryChartData({
      chart: paymentsHistoryChart,
      categories: getPaymentsHistoryChartCategories(),
    });

    const paymentsHistorySeries = [
      {
        name: i18n.t('dashboard.paidOnTime'),
        data: series.map(({ onTime }) => onTime?.amount),
      },
      {
        name: i18n.t('dashboard.late'),
        data: series.map(({ overdue }) => overdue?.amount),
      },
    ];

    return {
      type: 'bar',
      title: i18n.t('dashboard.paymentsHistoryChart'),
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          stacked: true,
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          markers: {
            radius: 12,
          },
          itemMargin: {
            vertical: 5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            barHeight: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
            hideOverflowingLabels: false,
          },
          style: {
            fontSize: '11px',
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [primary, colors.red.lighten1],
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
          custom: ({ series: SERIES, seriesIndex, dataPointIndex, w }) => {
            const amount = SERIES[seriesIndex][dataPointIndex];

            let count;
            let tooltipTitle = '';

            if (seriesIndex === 0) {
              tooltipTitle = i18n.t('dashboard.paidOnTime');
              count = series[dataPointIndex].onTime.count;
            } else if (seriesIndex === 1) {
              tooltipTitle = i18n.t('dashboard.late');
              count = series[dataPointIndex].overdue.count;
            } else {
              count = 0;
            }

            return `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    ${labelFormat(categories[dataPointIndex])}
                </div>

                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold;">
                        <div class="apexcharts-tooltip-y-group px-1">
                            <span class="apexcharts-tooltip-text-value">
                                <span style="vertical-align: middle">
                                    ${tooltipTitle}: ${formatCurrency(amount, currency)}
                                </span>

                                <br />

                                <span>${count} ${i18n.t('nav.invoices')}</span>
                            </span>
                        </div>
                    </div>
                </div>`;
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: paymentsHistorySeries,
    };
  },
  termsOfPaymentsChart: ({ termsOfPaymentsChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;

    const chartColors = {
      net0: primary,
      net30: lightPrimary,
      net45: bluePurple,
      net60: azure,
      net90: lightAzure,
      net120: colors.red.lighten1,
      net30Eom: colors.red.lighten2,
      net60Eom: colors.red.lighten3,
      net90Eom: colors.red.lighten4,
      other: secondary,
    };

    const seriesData = [];

    for (const termOfPayment of Object.keys(termsOfPaymentsChart)) {
      const value = get(termsOfPaymentsChart[termOfPayment], 'numberOfInvoices', 0);

      if (value > 0) {
        seriesData.push({
          color: chartColors[termOfPayment],
          label: i18n.t(`dashboard.termsOfPaymentsLabels.${termOfPayment}`),
          value,
        });
      }
    }

    const series = seriesData.map(({ value }) => value);

    return {
      type: 'donut',
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
        },
        labels: seriesData.map(({ label }) => label),
        colors: seriesData.map(({ color }) => color),
        dataLabels: {
          formatter: (val) => `${Math.round(val)}%`,
          dropShadow: {
            enabled: false,
          },
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: false,
          y: {
            formatter: (val) => `${val} ${i18n.t('invoice.invoices').toLowerCase()}`,
          },
        },
        grid: {
          padding: {
            top: 10,
            bottom: 5,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontWeight: 700,
                  fontSize: '40',
                  color: darkMode ? 'white' : teal,
                  offsetY: 5,
                  formatter: () => formatBigNumbers(sum(series)),
                },
                value: {
                  show: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 400,
                  fontSize: '14',
                  color: darkMode ? 'white' : teal,
                  formatter: () => i18n.t('invoice.invoices'),
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 700,
                  fontSize: '40',
                  color: darkMode ? 'white' : teal,
                  label: Number(sum(series)),
                  formatter: () => i18n.t('invoice.invoices'),
                },
              },
            },
          },
        },
      },
      series,
    };
  },
  dsoChart: ({ dsoChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const labelFormat = (date) =>
      format(date, 'MMM yy', {
        locale: locales[i18n.locale],
      });

    const columnWidth = (columnsCount) => (columnsCount <= 5 ? `${columnsCount * 10}%` : '50%');

    const dso = sortBy(
      isEqual(Vue.auth.user().selectedCompany.type, 'demo')
        ? getDemoDSOChart()
        : dsoChart.map(({ timestamp, value }) => ({
            date: subDays(new Date(timestamp), 1),
            value,
          })),
      'date',
    ).filter(({ value }) => value > 0);

    return {
      type: 'bar',
      title: i18n.t('dashboard.dso'),
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: columnWidth(dso.length),
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
        },
        colors: [colors.blue.lighten3],
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => labelFormat(value),
          },
          fillSeriesColor: true,
        },
        xaxis: {
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories: dso.map(({ date }) => date),
        },
      },
      series: [
        {
          name: 'DSO',
          data: dso.map(({ value }) => value),
        },
      ],
    };
  },
  totalARCompareChart: ({ totalARCompareChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const labelFormat = (date) =>
      format(new Date(date), 'MMM dd, yyyy', {
        locale: locales[i18n.locale],
      });

    const { currency } = Vue.auth.user().selectedCompany;
    const { totalAR, totalOverdue, categories } = totalARCompareChart;
    const {
      totalARCompareTrends: { totalAR: demoARTrend, totalOverdue: demoOverdueTrend },
    } = demoCompanyData;

    return {
      type: 'bar',
      title: i18n.t('dashboard.totalAR'),
      trends: [
        getTrend({
          name: 'greaterThan',
          key: i18n.t('dashboard.totalAR'),
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? demoARTrend
            : getMetricPercentage({
                pastValue: totalAR ? totalAR[0] : 0,
                currentValue: totalAR ? totalAR[1] : 0,
              }),
        }),
        getTrend({
          name: 'lessThan',
          key: i18n.t('dashboard.totalOverdueAR'),
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? demoOverdueTrend
            : getMetricPercentage({
                pastValue: totalOverdue ? totalOverdue[0] : 0,
                currentValue: totalOverdue ? totalOverdue[1] : 0,
              }),
        }),
      ],
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
        },
        colors: [primary, colors.red.lighten1],
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => labelFormat(value),
          },
          fillSeriesColor: true,
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.totalAR'),
          data: totalAR,
        },
        {
          name: i18n.t('dashboard.totalOverdueAR'),
          data: totalOverdue,
        },
      ],
    };
  },
  overdueCustomersCompareChart: ({ overdueCustomersCompareChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const labelFormat = (date) =>
      format(new Date(date), 'MMM dd, yyyy', {
        locale: locales[i18n.locale],
      });

    const { series, categories } = overdueCustomersCompareChart;
    const { overdueCustomersCompareTrend } = demoCompanyData;

    return {
      type: 'bar',
      title: i18n.t('dashboard.pastDueCustomers'),
      trends: [
        getTrend({
          name: 'lessThan',
          key: i18n.t('dashboard.pastDueCustomers'),
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? overdueCustomersCompareTrend
            : getMetricPercentage({
                pastValue: series ? series[0] : 0,
                currentValue: series ? series[1] : 0,
              }),
        }),
      ],
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
        },
        colors: [colors.red.lighten1],
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => labelFormat(value),
          },
          fillSeriesColor: true,
        },
        xaxis: {
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.pastDueCustomers'),
          data: series,
        },
      ],
    };
  },
  outstandingInvoicesCompareChart: ({ outstandingInvoicesCompareChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const labelFormat = (date) =>
      format(new Date(date), 'MMM dd, yyyy', {
        locale: locales[i18n.locale],
      });

    const { outstanding, overdue, categories } = outstandingInvoicesCompareChart;
    const {
      outstandingInvoicesCompareTrends: { outstanding: demoOutstandingTrend, overdue: demoOverdueTrend },
    } = demoCompanyData;

    return {
      type: 'bar',
      title: i18n.t('dashboard.openInvoices'),
      trends: [
        getTrend({
          name: 'greaterThan',
          key: i18n.t('dashboard.openInvoices'),
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? demoOutstandingTrend
            : getMetricPercentage({
                pastValue: outstanding ? outstanding[0] : 0,
                currentValue: outstanding ? outstanding[1] : 0,
              }),
        }),
        getTrend({
          name: 'lessThan',
          key: i18n.t('dashboard.openPastDueInvoices'),
          value: isEqual(Vue.auth.user().selectedCompany.type, 'demo')
            ? demoOverdueTrend
            : getMetricPercentage({
                pastValue: overdue ? overdue[0] : 0,
                currentValue: overdue ? overdue[1] : 0,
              }),
        }),
      ],
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
        },
        colors: [colors.orange.lighten3, colors.red.lighten1],
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => labelFormat(value),
          },
          fillSeriesColor: true,
        },
        xaxis: {
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.openInvoices'),
          data: outstanding,
        },
        {
          name: i18n.t('dashboard.openPastDueInvoices'),
          data: overdue,
        },
      ],
    };
  },
  agingBucketsCompareChart: ({ agingBucketsCompareChart }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const labelFormat = (date) =>
      format(new Date(date), 'MMM dd, yyyy', {
        locale: locales[i18n.locale],
      });

    const { currency } = Vue.auth.user().selectedCompany;
    const { buckets, categories } = agingBucketsCompareChart;
    const series = getAgingBucketsCompareChartSeries({ buckets });
    const chartColors = isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? [primary, colors.red.lighten4, colors.red.lighten3, colors.red.lighten2, colors.red.lighten1]
      : getAgingBucketsCompareChartColors({ buckets });

    return {
      type: 'bar',
      title: i18n.t('dashboard.agingBuckets'),
      trends: getAgingBucketsCompareChartTrends({ buckets }),
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -8,
          style: {
            fontSize: '0.765rem',
          },
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
          },
        },
        colors: chartColors,
        tooltip: {
          theme: 'dark',
          x: {
            formatter: (value) => labelFormat(value),
          },
          fillSeriesColor: true,
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          labels: {
            formatter: (value) => labelFormat(value),
          },
          categories,
        },
      },
      series,
    };
  },
  paymentsForecastCompareChart: ({ paymentsForecastCompareChart: { past, future, type } }, _, rootGetters) => {
    const {
      app: { darkMode },
    } = rootGetters;
    const { currency } = Vue.auth.user().selectedCompany;

    const {
      settings: {
        companySettings: { weekStartsOn },
      },
    } = rootGetters;

    const monthlyLabelFormat = (date) => {
      if (!isEqual(date, 'Future')) {
        return format(date, 'MMM yy', {
          locale: locales[i18n.locale],
        });
      }

      return i18n.t('dashboard.charts.paymentsForecast.future');
    };

    const weeklyLabelFormat = (date) => {
      if (!isEqual(date, 'Future')) {
        return `${format(date, 'MMM', {
          locale: locales[i18n.locale],
        })} ${i18n.t('dashboard.charts.paymentsForecast.weekPrefix', {
          weekNumber: getWeekOfMonth(date, { weekStartsOn }),
        })}`;
      }

      return i18n.t('dashboard.charts.paymentsForecast.future');
    };

    let categories = [];
    let pastData = [];
    let futureData = [];
    let promiseToPayData = [];

    if (isEqual(type, 'weekly')) {
      const weekly = getWeeklyPaymentsForecastChart({ past, future });

      categories = weekly.categories;
      pastData = weekly.pastData;
      futureData = weekly.futureData;
      promiseToPayData = weekly.promiseToPayData;
    } else {
      const monthly = getMonthlyPaymentsForecastChart({ past, future });

      categories = monthly.categories;
      pastData = monthly.pastData;
      futureData = monthly.futureData;
      promiseToPayData = monthly.promiseToPayData;
    }

    return {
      type: 'bar',
      title: i18n.t('dashboard.payments'),
      options: {
        ...chartOptions,
        theme: {
          mode: darkMode ? 'dark' : 'light',
        },
        chart: {
          animations,
          background: 'transparent',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          stacked: true,
          events: {
            dataPointMouseEnter(event) {
              event.target.style.cursor = 'default';
            },
          },
        },
        legend: {
          position: 'top',
          itemMargin: {
            vertical: 5,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            barHeight: '50%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          background: {
            enabled: true,
            foreColor: darkMode ? '#fff' : '#000',
            opacity: 0,
            hideOverflowingLabels: false,
          },
          style: {
            fontSize: '11px',
          },
          formatter: (val) => formatCurrencyBigNumbers(val, currency),
        },
        colors: [primary, colors.lightBlue.darken2, colors.blue.lighten1],
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        tooltip: {
          theme: 'dark',
          fillSeriesColor: true,
          y: {
            formatter: (val) => formatCurrency(val, currency, false),
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatCurrencyBigNumbers(val, currency),
          },
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: (value) => (isEqual(type, 'weekly') ? weeklyLabelFormat(value) : monthlyLabelFormat(value)),
          },
          categories,
        },
      },
      series: [
        {
          name: i18n.t('dashboard.paid'),
          data: pastData,
        },
        {
          name: i18n.t('dashboard.promiseToPay'),
          data: promiseToPayData,
        },
        {
          name: i18n.t('dashboard.projected'),
          data: futureData,
        },
      ],
    };
  },
};
