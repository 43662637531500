export default {
  dashboards: [],
  selectedDashboardId: undefined,
  selectedDashboard: [],
  selectedLayout: [],
  selectedWidgets: [],
  gavitiDashboardId: '8a13f76e-66af-416a-b4a1-a7ea57d189c1',
  defaultDashboardId: undefined,
  dashboardEditMode: false,
  selectedDashboardName: '',
  defaultDashboardLayout: [
    {
      x: 0,
      y: 0,
      w: 2,
      h: 4,
      i: '0',
      type: 'totalAR',
    },
    {
      x: 2,
      y: 0,
      w: 2,
      h: 4,
      i: '1',
      type: 'dso',
    },
    {
      x: 10,
      y: 0,
      w: 2,
      h: 4,
      i: '2',
      type: 'openInvoices',
    },
    {
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      i: '3',
      type: 'agingBuckets',
    },
    {
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      i: '4',
      type: 'openInvoicesAmountChart',
    },
    {
      x: 0,
      y: 4,
      w: 3,
      h: 4,
      i: '5',
      type: 'closedInvoices',
    },
    {
      x: 3,
      y: 4,
      w: 3,
      h: 4,
      i: '6',
      type: 'workflowsActivities',
    },
    {
      x: 6,
      y: 4,
      w: 3,
      h: 4,
      i: '7',
      type: 'notesActivities',
    },
    {
      x: 0,
      y: 24,
      w: 4,
      h: 8,
      i: '8',
      type: 'topDebtors',
    },
    {
      x: 8,
      y: 24,
      w: 4,
      h: 8,
      i: '9',
      type: 'pastDueCustomers',
    },
    {
      x: 8,
      y: 16,
      w: 4,
      h: 8,
      i: '10',
      type: 'payments',
    },
    {
      x: 4,
      y: 4,
      w: 4,
      h: 8,
      i: '11',
      type: 'openInvoicesChart',
    },
    {
      x: 8,
      y: 10,
      w: 4,
      h: 8,
      i: '12',
      type: 'customersChart',
    },
    {
      x: 8,
      y: 15,
      w: 4,
      h: 8,
      i: '13',
      type: 'invoicesChart',
    },
    {
      x: 8,
      y: 0,
      w: 2,
      h: 4,
      i: '14',
      type: 'cei',
    },
    {
      x: 8,
      y: 8,
      w: 4,
      h: 4,
      i: '15',
      type: 'manualNotesActivities',
    },
    {
      x: 8,
      y: 12,
      w: 4,
      h: 4,
      i: '16',
      type: 'inflation',
    },
    {
      x: 8,
      y: 8,
      w: 4,
      h: 4,
      i: '17',
      type: 'interest',
    },
    {
      x: 6,
      y: 0,
      w: 2,
      h: 4,
      i: '18',
      type: 'dsoStandard',
    },
    {
      x: 4,
      y: 0,
      w: 2,
      h: 4,
      i: '19',
      type: 'add',
    },
    {
      x: 6,
      y: 0,
      w: 2,
      h: 4,
      i: '20',
      type: 'mdd',
    },
    {
      x: 8,
      y: 0,
      w: 2,
      h: 4,
      i: '21',
      type: 'collectionRate',
    },
    {
      x: 4,
      y: 24,
      w: 4,
      h: 8,
      i: '22',
      type: 'customersRiskChart',
    },
    {
      x: 0,
      y: 16,
      w: 4,
      h: 8,
      i: '23',
      type: 'termsOfPayments',
    },
    {
      x: 9,
      y: 4,
      w: 3,
      h: 4,
      i: '24',
      type: 'manualTimeSaved',
    },
    {
      x: 4,
      y: 16,
      w: 4,
      h: 8,
      i: '25',
      type: 'paymentGatewayChart',
    },
    {
      x: 4,
      y: 16,
      w: 4,
      h: 8,
      i: '26',
      type: 'paymentsHistoryChart',
    },
  ],
  widgets: [],
  trends: {
    totalAr: undefined,
    countOutstandingInvoices: undefined,
  },
};
