import { creditManagementService, customerService } from '@services';
import { isEmpty, uniq } from 'lodash-es';
import Vue from 'vue';

export default {
  fetchCreditRequests: async ({ commit, dispatch }, params) => {
    try {
      const { data, total } = await creditManagementService.list(params);

      await dispatch('fetchCreditRequestsCustomers', data);

      commit('SET_CREDIT_REQUESTS', data);
      commit('SET_TOTAL_CREDIT_REQUESTS', total);
    } catch (error) {
      console.warn(error);
    }
  },
  updateCreditRequest: async ({ commit }, params) => {
    try {
      const { id, ...rest } = params;
      const payload = {
        companyId: Vue.auth.user().selectedCompany.id,
        ...rest,
      };

      await creditManagementService.update({ id, payload });
    } catch (error) {
      console.warn(error);
    }
  },
  sendCreditRequest: async ({ commit }, params) => {
    try {
      await creditManagementService.send(params);
    } catch (error) {
      console.warn(error);
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  fetchCreditRequestsCustomers: async ({ commit }, creditRequests) => {
    try {
      if (Array.isArray(creditRequests) && creditRequests.length > 0) {
        const customerIds = uniq(
          creditRequests.filter(({ customerId }) => !!customerId).map(({ customerId }) => customerId),
        );

        if (!isEmpty(customerIds)) {
          await customerService
            .list({
              companyId: Vue.auth.user().selectedCompany.id,
              search: { ids: customerIds },
            })
            .then((res) => commit('SET_CREDIT_REQUESTS_CUSTOMERS_LIST', res?.list || []));
        } else {
          commit('SET_CREDIT_REQUESTS_CUSTOMERS_LIST', []);
        }
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCreditReport: async ({ commit }, params) => {
    try {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          widgetId: 'creditRequest',
          value: true,
        },
        { root: true },
      );

      return creditManagementService.report(params);
    } catch (error) {
      console.warn(error);
    } finally {
      commit(
        'widgets/SET_LOADING_WIDGETS',
        {
          widgetId: 'creditRequest',
          value: false,
        },
        { root: true },
      );
    }
  },
  requestCreditReportCompanies: async ({}, params) => {
    return creditManagementService.requestReportCompanies(params);
  },
  requestCreditReport: async ({ dispatch }, params) => {
    const res = await creditManagementService.requestReport(params);

    await dispatch(
      'settings/fetchAndUpdateCompanySetting',
      {
        companyId: Vue.auth.user().selectedCompany.id,
        name: 'creditReportUsage',
      },
      { root: true },
    );

    return res;
  },
};
