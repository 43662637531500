const LAYOUTS = {
  APP: 'AppLayout',
  LOADING: 'LoadingLayout',
  MAIN: 'MainLayout',
  BASE: 'BaseLayout',
  COMPANIES: 'CompaniesLayout',
  AUTH: 'AuthLayout',
  SETTINGS: 'SettingsLayout',
};

Object.freeze(LAYOUTS);

export default LAYOUTS;
