import Vue from 'vue';

import { LAYOUTS, PERMISSIONS } from '@enums';

const {
  RESOURCES: {
    DASHBOARDS,
    ACTIVITY_LOG,
    MY_ACTIONS,
    INVOICES,
    CUSTOMERS,
    WORKFLOWS,
    DOCUMENTS,
    AR_TEAM,
    CREDIT_MANAGEMENT,
    BANK_RECONCILIATION,
  },
  POSSESSIONS: { ANY, OWN },
  ACTIONS: { READ },
} = PERMISSIONS;

const canEnter = (permissions, next) => Vue.prototype.$can.enter(permissions, next);
const freeAccess = () => Vue.prototype.$can.freeAccess();

export default [
  {
    name: 'dashboard',
    path: '/li/dashboard',
    meta: {
      title: 'nav.dashboards',
      layout: LAYOUTS.MAIN,
      isDashboard: true,
      auth: true,
    },
    component: () => import('@pages/Dashboard'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: OWN, resource: DASHBOARDS }, next);
    },
  },
  {
    name: 'activity-log',
    path: '/li/activity-log',
    meta: {
      title: 'nav.activityLog',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/ActivityLog'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: OWN, resource: ACTIVITY_LOG }, next);
    },
  },
  {
    name: 'my-actions',
    path: '/li/my-actions',
    meta: {
      title: 'nav.myActions',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/MyActions'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: OWN, resource: MY_ACTIONS }, next);
    },
  },
  {
    name: 'invoices',
    path: '/li/invoices',
    meta: {
      title: 'nav.invoices',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/Invoices'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: OWN, resource: INVOICES }, next);
    },
  },
  {
    name: 'customers',
    path: '/li/customers',
    meta: {
      title: 'nav.customer',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/Customers/index'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: OWN, resource: CUSTOMERS }, next);
    },
  },
  {
    name: 'workflows',
    path: '/li/workflows',
    meta: {
      title: 'nav.workflows',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/Workflows'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: ANY, resource: WORKFLOWS }, next);
    },
  },
  {
    name: 'workflow-rules',
    path: '/li/workflows/:workflowId/rules',
    meta: {
      title: 'nav.workflows',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@components/Workflows/Workflow'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: ANY, resource: WORKFLOWS }, next);
    },
  },
  {
    name: 'workflow-templates',
    path: '/li/workflows/templates',
    meta: {
      title: 'nav.workflows',
      layout: LAYOUTS.MAIN,
      auth: true,
      nonFree: true,
    },
    component: () => import('@views/Workflows/TemplatesManager'),
    beforeEnter(_to, _from, next) {
      if (!Vue.prototype.$can.support()) {
        return next({ path: '/li/dashboard' });
      }

      return next();
    },
  },
  {
    name: 'documents',
    path: '/li/documents',
    meta: {
      title: 'nav.documents',
      layout: LAYOUTS.MAIN,
      auth: true,
      nonFree: true,
    },
    component: () => import('@views/Documents'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: READ, possession: ANY, resource: DOCUMENTS }, next);
    },
  },
  {
    name: 'ar-team',
    path: '/li/ar-team',
    meta: {
      title: 'nav.arTeam',
      layout: LAYOUTS.MAIN,
      auth: true,
      nonFree: true,
    },
    component: () => import('@views/ARTeam'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: READ, possession: ANY, resource: AR_TEAM }, next);
    },
  },
  {
    name: 'credit-management',
    path: '/li/credit-management',
    meta: {
      title: 'nav.creditManagement',
      layout: LAYOUTS.MAIN,
      auth: true,
      nonFree: true,
    },
    component: () => import('@pages/CreditManagementPage'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: READ, possession: ANY, resource: CREDIT_MANAGEMENT }, next);
    },
  },
  {
    name: 'bank-reconciliation',
    path: '/li/bank-reconciliation',
    meta: {
      title: 'nav.bankReconciliation',
      layout: LAYOUTS.MAIN,
      auth: true,
      nonFree: true,
    },
    component: () => import('@pages/BankReconciliationPage'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: READ, possession: ANY, resource: BANK_RECONCILIATION }, next);
    },
  },
];
