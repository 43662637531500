import Vue from 'vue';

import { isEmpty } from 'lodash-es';
import { httpClient } from '@api';

import { CreditRequest } from './credit-management.dto';

export default {
  list: async (params) => {
    try {
      let normalizedList = [];

      const { data, total } = await httpClient.post('credit-request/list', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = data.map((item) => new CreditRequest(item));
      }

      return { data: normalizedList, total };
    } catch (error) {
      console.warn(error);
    }
  },
  update: async ({ id, payload }) => {
    try {
      await httpClient.put(`credit-request/${id}`, payload);
    } catch (error) {
      console.warn(error);
    }
  },
  send: async (payload) => {
    try {
      await httpClient.post('credit-request/email', payload);
    } catch (error) {
      console.warn(error);
    }
  },
  report: async ({ type, ...payload }) => {
    try {
      return await httpClient.post('credit-report/get/report', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...payload,
      });
    } catch (error) {
      console.warn(error);
    }
  },
  requestReportCompanies: async ({ type, ...payload }) => {
    return httpClient.post(`credit-report/credit/companies/${type}`, {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...payload,
    });
  },
  requestReport: async ({ type, ...payload }) => {
    return httpClient.post(`credit-report/generate/report/${type}`, {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...payload,
    });
  },
};
