/* eslint-disable */
import Vue from 'vue';

import { i18n } from '@plugins';

const notice = {};

/**
 * Create Notice plugin
 *
 * @param   {object}  Vue
 * @return  {object}
 */

notice.install = (Vue) => {
  const typeNotice = {
    iconPack: 'mdi',
    success: {
      theme: 'toasted-primary',
      className: 'v-alert v-sheet elevation-2 v-alert--dense toasted-alert success',
      position: 'bottom-right',
      duration: 5000,
    },
    error: {
      theme: 'toasted-primary',
      className: 'v-alert v-sheet elevation-2 v-alert--dense toasted-alert error',
      position: 'bottom-right',
      action: {
        text: i18n.t('menu.dismiss'),
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    },
  };

  Vue.prototype.$showToast = (type, text) => {
    if (typeNotice[type]) {
      return Vue.toasted.show(text, typeNotice[type]);
    }

    throw new Error(`Type "${type}" for notifications does not exist`);
  };
};

Vue.use(notice);

export default notice;
