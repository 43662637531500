import { isEqual } from 'lodash-es';

export default {
  SET_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
  SET_INVOICES: (state, payload) => {
    state.invoices = payload;
  },
  SET_STATS: (state, payload) => {
    state.stats = payload;
  },
  SET_TOTAL_AR_BALANCE: (state, payload) => {
    state.totalARBalance = payload;
  },
  SET_SELECTED: (state, payload) => {
    state.selected = payload;
  },
  UPDATE_INVOICE_WORKFLOW_ENABLED: (state, { invoiceId, workflowEnabled }) => {
    const invoice = state.invoices.find(({ id }) => isEqual(id, invoiceId));

    if (invoice) {
      invoice.workflowEnabled = workflowEnabled;
    }
  },
};
