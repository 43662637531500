export default {
  SET_CREDIT_REQUESTS: (state, creditRequests) => {
    state.creditRequests = creditRequests;
  },
  SET_TOTAL_CREDIT_REQUESTS: (state, total) => {
    state.total = total;
  },
  SET_SELECTED: (state, payload) => {
    state.selected = payload;
  },
  SET_CREDIT_REQUESTS_CUSTOMERS_LIST: (state, customers) => {
    state.customers = customers;
  },
};
