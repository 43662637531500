export class CreditRequest {
  constructor(objInit) {
    this.status = objInit.status;
    this.id = objInit.id;
    this.customerName = objInit.customerName;
    this.customerId = objInit.customerId;
    this.requestedCredit = objInit.requestedCredit;
    this.approvedCredit = objInit.approvedCredit;
    this.localRequestedCredit = objInit.localRequestedCredit;
    this.localApprovedCredit = objInit.localApprovedCredit;
    this.requestedAt = objInit.requestedAt;
    this.approvedAt = objInit.approvedAt;
    this.form = objInit.form;
    this.fileAttached = objInit.fileAttached;
    this.attachedFiles = objInit.attachedFiles;
    this.sentTo = objInit.sentTo;
  }
}
