import Vue from 'vue';

import { LAYOUTS, ENVS, DOMAINS } from '@enums';
import { isEqual, isNil } from 'lodash-es';

const decodeEmail = (email) => decodeURIComponent(email.replace(/\ /g, '+'));

const { MODE } = import.meta.env;

const isProduction = isEqual(MODE, ENVS.PRODUCTION);

function urlHandler(url) {
  if (!isProduction) {
    return `http://${url}:8080`;
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }

  return url;
}

export default [
  {
    name: 'login',
    path: '/login',
    meta: {
      title: 'Login',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/Login'),
    beforeEnter: (_to, _from, next) => {
      if (Vue.auth.check()) {
        return next({
          path: '/li/dashboard',
        });
      }

      const isUS = isEqual(window.location.hostname, DOMAINS.US);

      if (isUS && !_to.query?.email) {
        window.location.replace(urlHandler(DOMAINS.EU));
      }

      return next();
    },
  },
  {
    name: 'register',
    path: '/register',
    meta: {
      title: 'Sign up',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/SignUp'),
    beforeEnter: (_to, _from, next) => {
      if (Vue.auth.check()) {
        return next({
          path: '/li/dashboard',
        });
      }

      return next();
    },
  },
  {
    name: 'verify-account',
    path: '/verify-account',
    meta: {
      title: 'Verify Account',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/VerifyAccount'),
    beforeEnter: (_to, _from, next) => {
      if (Vue.auth.check()) {
        return next({
          path: '/li/dashboard',
        });
      }

      return next();
    },
  },
  {
    name: 'forgot-password',
    path: '/login/forgot',
    meta: {
      title: 'Forgot Password',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/ForgotPassword'),
  },
  {
    name: 'reset-password',
    path: '/login/reset/:token',
    meta: {
      title: 'Reset Password',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/ResetPassword'),
  },
  {
    name: 'sso',
    path: '/login/sso',
    meta: {
      title: 'SSO',
      layout: LAYOUTS.AUTH,
    },
    component: () => import('@components/Auth/SSO'),
    beforeEnter({ query }, _from, next) {
      const { securityPolicyId, token, email } = query;

      if (!isNil(securityPolicyId) || (!isNil(token) && !isNil(email))) {
        if (Vue.auth.check() && !isNil(email) && isEqual(Vue.auth.user()?.email, decodeEmail(email))) {
          return next('/li/dashboard');
        }

        localStorage.clear();

        return next();
      }

      return next('/login');
    },
  },
];
