export class AllocationSuggestion {
  constructor(objInit) {
    this.id = objInit.id;
    this.type = objInit.type;
    this.customerId = objInit.customerId;
    this.displayName = objInit.displayName;
    this.invoiceIds = objInit.invoiceIds;
    this.values = objInit.values;
    this.status = objInit.status;
    this.score = objInit.score;
  }
}
