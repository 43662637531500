const AUTH = {
  VERIFICATION_METHODS: {
    TURNSTILE: {
      NAME: 'turnstile',
      TOKEN_KEY: 'turnstileToken',
      SRC: '//challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback&render=explicit',
    },
  },
};

Object.freeze(AUTH);

export default AUTH;
