import { isEqual, groupBy, cloneDeep } from 'lodash-es';
import { i18n } from '@plugins';

function getGroupTags(tags, vueContext) {
  return tags
    .filter((tag) => !tag.includes('.CF.') && !tag.includes('PAYLINK'))
    .map((tag) => ({
      name: vueContext.$t(`workflow.tags.${tag}`),
      value: `{{${tag}}}`,
    }));
}

function getUcrTags(tags, vueContext, type) {
  return tags.map((value) => ({
    name: vueContext.$t(`workflow.tags.${value.replace(`.${type}`, '')}`),
    value: `{{${value}}}`,
  }));
}

function getCfTags(locale, type, removeCompanyTags, customFields = {}) {
  return removeCompanyTags
    ? []
    : Object.entries(customFields)
        .map(([key, { active, translations }]) => {
          if (active) {
            const name = translations[locale];

            return {
              name,
              value: `{{${type}.CF.${key.toUpperCase()}}}`,
            };
          }
        })
        .filter(Boolean);
}

export default {
  workflow: (state) => (workflowId) => state.workflowsData.find(({ id }) => isEqual(id, workflowId)),
  totalLocalBalance: ({ totalLocalBalance }) => totalLocalBalance,
  invoicesCountOutstanding: ({ invoicesCountOutstanding }) => invoicesCountOutstanding,
  sortedWorkflows: (state, _getters, _rootState, rootGetters) =>
    [...state.workflowsData].sort((el) => (el.id === rootGetters['settings/defaultWorkflowId'] ? -1 : 1)),
  workflowsData: ({ workflowsData }) => workflowsData,
  workflowDataInactiveSuffix: ({ workflowsData }) => {
    const workflowDataCloned = cloneDeep(workflowsData);

    return workflowDataCloned.map((workflow) => {
      if (!workflow.active) {
        workflow.name += i18n.t('workflow.dashInactiveRule');
      }

      return workflow;
    });
  },
  workflowName: (_state, _getters) => (workflowId) => _getters.workflow(workflowId)?.name,
  selected: ({ selected }) => selected,
  stats: ({ stats }) => stats,
  ruleStats: ({ ruleStats }) => ruleStats,
  defaultHeaders: () => [
    'default',
    'active',
    'name',
    'rules',
    'days',
    'hours',
    'oneEmailPerCustomer',
    'rank',
    'engagements',
    'assignedCustomers',
    'invoices',
    'closedInvoices',
    'overdueBalancePercent',
  ],
  tagGroups: (state, _getters, _rootState, rootGetters) => (type, vueContext) => {
    let tags = state.allowedTags[type];

    tags = groupBy(tags, (v) => v.split('.', 1));

    if (tags.CUSTOMER) {
      const customerTags = groupBy(tags.CUSTOMER, (v) => {
        const prefix = v.split('.', 2).join('.');

        return ['CUSTOMER.METRIC', 'CUSTOMER.UCR'].includes(prefix) ? prefix : 'CUSTOMER';
      });

      if (customerTags['CUSTOMER.UCR']) {
        customerTags['CUSTOMER.UCR'] = groupBy(customerTags['CUSTOMER.UCR'], (v) => v.split('.', 3)[2]);
      }

      tags = { ...tags, ...customerTags };
    }

    const tagGroups = [];

    if (tags.CUSTOMER) {
      const isCreditManagementEnabled = rootGetters['settings/toggleCreditManagement'];
      const isDemoCompany = isEqual(vueContext.$auth.user().selectedCompany.type, 'demo');
      const isRecurring = isEqual(vueContext.rule.trigger, 'recurring');
      const customerTags = cloneDeep(tags.CUSTOMER);

      if (isRecurring && isDemoCompany) {
        tags.CUSTOMER = customerTags;
      } else if (!isRecurring || !isCreditManagementEnabled) {
        tags.CUSTOMER = customerTags.filter((tag) => !tag.includes('CUSTOMER.CREDIT.FORM'));
      }

      tagGroups.push({
        type: vueContext.$t('workflow.tags.customer'),
        tags: [
          ...getGroupTags(tags.CUSTOMER, vueContext),
          ...getCfTags(
            vueContext.$i18n.locale,
            'CUSTOMER',
            vueContext.removeCompanyTags,
            rootGetters['settings/customFieldsAll']('customer'),
          ),
        ],
      });
    }

    if (tags['CUSTOMER.METRIC']) {
      tagGroups.push({
        type: vueContext.$t('workflow.tags.customerMetric'),
        tags: getGroupTags(tags['CUSTOMER.METRIC'], vueContext),
      });
    }

    if (
      tags.INVOICE &&
      !isEqual(vueContext.rule.trigger, 'recurring') &&
      !(isEqual(vueContext.rule.trigger, 'dataChange') && isEqual(vueContext.rule.dataChangeType, 'customers'))
    ) {
      tagGroups.push({
        type: vueContext.$t('workflow.tags.invoice'),
        tags: [
          ...getGroupTags(tags.INVOICE, vueContext),
          ...getCfTags(
            vueContext.$i18n.locale,
            'INVOICE',
            vueContext.removeCompanyTags,
            rootGetters['settings/customFieldsAll']('invoice'),
          ),
        ],
      });
    }

    if (tags['CUSTOMER.UCR'] && !vueContext.removeCompanyTags) {
      const userCustomerRelationsAll = rootGetters['settings/userCustomerRelationsAll'];

      for (const key in userCustomerRelationsAll) {
        if (userCustomerRelationsAll.hasOwnProperty(key) && userCustomerRelationsAll[key]?.active) {
          const ucr = userCustomerRelationsAll[key];

          tagGroups.push({
            type: ucr.translations[vueContext.$i18n.locale],
            tags: getUcrTags(tags['CUSTOMER.UCR'][key.toUpperCase()], vueContext, key.toUpperCase()),
          });
        }
      }
    }

    if (tags.COMPANY) {
      tagGroups.push({
        type: vueContext.$t('workflow.tags.company'),
        tags: getGroupTags(tags.COMPANY, vueContext),
      });
    }

    if (tags.DATE) {
      tagGroups.push({
        type: vueContext.$t('workflow.tags.date'),
        tags: getGroupTags(tags.DATE, vueContext),
      });
    }

    return tagGroups;
  },
};
