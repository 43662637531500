import { keyBy } from 'lodash-es';
import Vue from 'vue';

const sumValues = (obj) => Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);

export default {
  FETCH_COMPANIES_TOTAL_LOCAL_BALANCE({ companiesMetricData, unifiedMetricData }, totalLocalBalance) {
    companiesMetricData.totalLocalBalance = totalLocalBalance;

    const companySettings = keyBy(Vue.auth.user().companies, 'id');
    const sumTotalLocalBalanceByCurrency = {};

    for (const [companyId, amount] of Object.entries(totalLocalBalance)) {
      const { currency } = companySettings[companyId];

      if (!sumTotalLocalBalanceByCurrency[currency]) {
        sumTotalLocalBalanceByCurrency[currency] = 0;
      }

      sumTotalLocalBalanceByCurrency[currency] += amount;
    }

    unifiedMetricData.totalLocalBalance = sumTotalLocalBalanceByCurrency;
  },

  FETCH_COMPANIES_INVOICE_COUNT_OUTSTANDING({ companiesMetricData, unifiedMetricData }, outstanding) {
    companiesMetricData.outstanding = outstanding;
    unifiedMetricData.outstanding = sumValues(outstanding);
  },

  FETCH_COMPANIES_INVOICE_COUNT_OVERDUE({ companiesMetricData, unifiedMetricData }, overdue) {
    companiesMetricData.overdue = overdue;
    unifiedMetricData.overdue = sumValues(overdue);
  },

  FETCH_COMPANIES_AGING({ companiesMetricData }, aging) {
    companiesMetricData.aging = aging;
  },

  FETCH_CUSTOMER_COUNT_OVERDUE({ companiesMetricData, unifiedMetricData }, customerOverdue) {
    companiesMetricData.customerOverdue = customerOverdue;
    unifiedMetricData.customerOverdue = sumValues(customerOverdue);
  },
};
