import { isEqual, isNil, isString, cloneDeep } from 'lodash-es';

import { i18n } from '@plugins';

export default {
  SET_DASHBOARDS: (state, payload) => {
    state.dashboards = payload;
  },
  SET_SELECTED_DASHBOARD: (state, { selectedDashboardId }) => {
    state.selectedDashboardId = selectedDashboardId;

    let selectedDashboard = state.dashboards.find(({ id }) => isEqual(id, selectedDashboardId));

    if (isNil(selectedDashboard)) {
      selectedDashboard = state.dashboards.find(({ locked }) => !isNil(locked) && locked);
      state.defaultDashboardId = selectedDashboard.id;
    }

    state.selectedLayout = cloneDeep(selectedDashboard.layout);

    const selectedWidgets = selectedDashboard.layout.map(({ type }) => type);

    state.selectedWidgets = selectedWidgets;
    state.selectedDashboardName = selectedDashboard.name;
  },
  SET_DEFAULT_DASHBOARD_ID: (state, defaultDashboardId) => {
    state.defaultDashboardId = defaultDashboardId;
  },
  UPDATE_SELECTED_LAYOUT: (state, payload) => {
    state.selectedLayout = payload;
  },
  SET_DASHBOARD_EDIT_MODE: (state) => {
    state.dashboardEditMode = !state.dashboardEditMode;
  },
  DELETE_DASHBOARD: (state, dashboardId) => {
    const dashboardIndex = state.dashboards.findIndex(({ id }) => isEqual(id, dashboardId));

    state.dashboards[dashboardIndex].deleted = true;
  },
  DISCARD_NEW_DASHBOARD: (state) => {
    state.dashboards = state.dashboards.filter(({ id }) => !isEqual(id, 'new'));
  },
  SET_SELECTED_WIDGETS: (state, widgets) => {
    state.selectedWidgets = widgets;

    const widgetTypes = state.selectedLayout.map(({ type }) => type);

    if (widgets) {
      for (const widgetKey of widgets) {
        if (!widgetTypes.includes(widgetKey)) {
          const defaultWidget = state.defaultDashboardLayout.find(({ type }) => isEqual(type, widgetKey));

          state.selectedLayout.push(defaultWidget);
        }
      }

      state.selectedLayout = state.selectedLayout.filter((widget) => widgets.includes(widget.type));
    }
  },
  SET_SELECTED_DASHBOARD_NAME: (state, selectedDashboardName) => {
    state.selectedDashboardName = isString(selectedDashboardName) ? selectedDashboardName.trim() : '';
  },
  UPDATE_WIDGET: (state, payload) => {
    const widgetIndex = state.selectedLayout.findIndex(({ i }) => isEqual(i, payload.i));

    state.selectedLayout[widgetIndex] = payload;
  },
  CREATE_DASHBOARD: (state, payload) => {
    state.dashboards.push(payload);
  },
  SET_CHART_CENTRAL_DASHBOARD: (state) => {
    state.selectedDashboardId = 'chart-central';
    state.selectedLayout = [];
    state.selectedDashboardName = i18n.t('nav.chartCentral');
  },
};
