export default {
  isLoading: ({ isLoading }) => isLoading,
  reloadPage: ({ reloadPage }) => reloadPage,
  modal: ({ modal }) => modal,
  companyUsers: (_, _rootState, rootGetters) => {
    const {
      settings: { companyUsers },
    } = rootGetters;

    return companyUsers;
  },
  companyTypes: ({ companyTypes }) => companyTypes,
  companyType: ({ companyType }) => companyType,
  companyDeleted: ({ companyDeleted }) => companyDeleted,
  companyDeletionData: ({ companyDeletionData }) => companyDeletionData,
  companyDeactivated: ({ companyDeactivated }) => companyDeactivated,
  companySettingsState: ({ companySettingsState }) => companySettingsState,
  companyNotificationTypes: ({ companyNotificationTypes }) => companyNotificationTypes,
  companyWorkflowTypes: ({ companyWorkflowTypes }) => companyWorkflowTypes,
  companyWorkflowTypesConfig: ({ companyWorkflowTypesConfig }) => companyWorkflowTypesConfig,
  companyPremiumFeatureTypes: ({ companyPremiumFeatureTypes }) => companyPremiumFeatureTypes,
  companyUCRTypes: ({ companyUCRTypes }) => companyUCRTypes,
  companyCustomFieldTypes: ({ companyCustomFieldTypes }) => companyCustomFieldTypes,
  companyAdapters: ({ companyAdapters }) => companyAdapters,
  companyAdapterTypes: ({ companyAdapterTypes }) => companyAdapterTypes,
  adapterEmails: ({ adapterEmails }) => adapterEmails,
  goLiveTimestamp: ({ goLiveTimestamp }) => goLiveTimestamp,
  businessModel: ({ businessModel }) => businessModel,
  businessModelTypes: ({ businessModelTypes }) => businessModelTypes,
  businessType: ({ businessType }) => businessType,
  businessTypes: ({ businessTypes }) => businessTypes,
  companyAPASettings: ({ companyAPASettings }) => companyAPASettings,
  billingIds: ({ billingIds }) => billingIds,
};
