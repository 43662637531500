import { workflowService } from '@services';

export default {
  getWorkflowTemplates: async ({ commit }, payload) => {
    try {
      const { type } = payload;

      const list = await workflowService.getTemplates(payload);

      commit('SET_TEMPLATES', { type, list });
    } catch (error) {
      console.error(error);
    }
  },
  insertTemplate: async ({ dispatch }, payload) => {
    try {
      const { type, companyId } = payload;

      const id = await workflowService.insertTemplate(payload);

      await dispatch('getWorkflowTemplates', { companyId, type });

      return id;
    } catch (error) {
      console.error(error);
    }
  },
  updateTemplate: async ({ dispatch }, payload) => {
    try {
      const { type, companyId } = payload;

      await workflowService.updateTemplate(payload);

      await dispatch('getWorkflowTemplates', { companyId, type });
    } catch (error) {
      console.error(error);
    }
  },
  deleteTemplate: async ({ dispatch }, payload) => {
    try {
      const { type, companyId } = payload;

      await workflowService.deleteTemplate(payload);

      await dispatch('getWorkflowTemplates', { companyId, type });
    } catch (error) {
      console.error(error);
    }
  },
  rephraseTemplate: async ({}, payload) => {
    try {
      const res = await workflowService.rephraseTemplate(payload);

      return res;
    } catch (error) {
      console.error(error);
    }
  },
  workflowTemplateDefaults: async ({ commit }, payload) => {
    try {
      const template = await workflowService.workflowTemplateDefaults(payload);

      commit('SET_WORKFLOW_TEMPLATE_DEFAULTS', { id: template.id, template });
    } catch (error) {
      console.error(error);
    }
  },
  defaultWorkflowRuleFields: async ({}, payload) => {
    try {
      return await workflowService.defaultWorkflowRuleFields(payload);
    } catch (error) {
      console.error(error);
    }
  },
};
