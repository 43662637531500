export default [
  {
    x: 0,
    y: 0,
    w: 2,
    h: 4,
    i: '0',
    type: 'totalAR',
  },
  {
    x: 2,
    y: 0,
    w: 2,
    h: 4,
    i: '1',
    type: 'dso',
  },
  {
    x: 4,
    y: 0,
    w: 2,
    h: 4,
    i: '19',
    type: 'add',
  },
  {
    x: 6,
    y: 0,
    w: 2,
    h: 4,
    i: '20',
    type: 'mdd',
  },
  {
    x: 8,
    y: 0,
    w: 2,
    h: 4,
    i: '21',
    type: 'collectionRate',
  },
  {
    x: 10,
    y: 0,
    w: 2,
    h: 4,
    i: '2',
    type: 'openInvoices',
  },
  {
    x: 0,
    y: 4,
    w: 3,
    h: 4,
    i: '5',
    type: 'closedInvoices',
  },
  {
    x: 3,
    y: 4,
    w: 3,
    h: 4,
    i: '6',
    type: 'workflowsActivities',
  },
  {
    x: 6,
    y: 4,
    w: 3,
    h: 4,
    i: '7',
    type: 'notesActivities',
  },
  {
    x: 9,
    y: 4,
    w: 3,
    h: 4,
    i: '24',
    type: 'manualTimeSaved',
  },
  {
    x: 0,
    y: 8,
    w: 4,
    h: 8,
    i: '3',
    type: 'agingBuckets',
  },
  {
    x: 4,
    y: 8,
    w: 4,
    h: 8,
    i: '4',
    type: 'openInvoicesAmountChart',
  },
  {
    x: 8,
    y: 8,
    w: 4,
    h: 4,
    i: '17',
    type: 'interest',
  },
  {
    x: 8,
    y: 12,
    w: 4,
    h: 4,
    i: '16',
    type: 'inflation',
  },
  {
    x: 0,
    y: 16,
    w: 4,
    h: 8,
    i: '23',
    type: 'termsOfPayments',
  },
  {
    x: 4,
    y: 16,
    w: 4,
    h: 8,
    i: '25',
    type: 'paymentGatewayChart',
  },
  {
    x: 8,
    y: 16,
    w: 4,
    h: 8,
    i: '10',
    type: 'payments',
  },
  {
    x: 0,
    y: 24,
    w: 4,
    h: 8,
    i: '8',
    type: 'topDebtors',
  },
  {
    x: 4,
    y: 24,
    w: 4,
    h: 8,
    i: '22',
    type: 'customersRiskChart',
  },
  {
    x: 8,
    y: 24,
    w: 4,
    h: 8,
    i: '26',
    type: 'paymentsHistoryChart',
  },
  {
    x: 0,
    y: 32,
    w: 4,
    h: 8,
    i: '9',
    type: 'pastDueCustomers',
  },
];
