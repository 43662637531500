import { cloneDeep, isEqual, isNil, last } from 'lodash-es';

import { dashboardService } from '@services';
import { i18n } from '@plugins';

export default {
  fetchDashboards: async ({ commit }, params) => {
    try {
      const dashboards = await dashboardService.list({ params });

      commit('SET_DASHBOARDS', dashboards);
    } catch ({ message }) {
      console.error(message);
    }
  },
  setDefaultDashboardId: async ({ commit, dispatch }, { companyId, defaultDashboardId }) => {
    commit('SET_DEFAULT_DASHBOARD_ID', defaultDashboardId);

    if (companyId) {
      await dispatch(
        'settings/updateCompanyUserSettings',
        {
          companyId,
          settings: {
            defaultDashboardId,
          },
        },
        { root: true },
      );
    }
  },
  setSelectedDashboard: ({ commit, rootGetters }, selectedDashboardId) => {
    commit('SET_SELECTED_DASHBOARD', { selectedDashboardId });
  },
  setChartCentralDashboard: ({ commit }) => {
    commit('SET_CHART_CENTRAL_DASHBOARD');
  },
  updateSelectedLayout: ({ commit }, payload) => {
    const updatedLayout = cloneDeep(payload);

    commit('UPDATE_SELECTED_LAYOUT', updatedLayout);
  },
  setDashboardEditMode: ({ commit }) => {
    commit('SET_DASHBOARD_EDIT_MODE');
  },
  setSelectedWidgets: ({ commit }, widgets) => {
    commit('SET_SELECTED_WIDGETS', widgets);
  },
  setSelectedDashboardName: ({ commit }, selectedDashboardName) => {
    commit('SET_SELECTED_DASHBOARD_NAME', selectedDashboardName);
  },
  updateWidget: ({ commit }, payload) => {
    commit('UPDATE_WIDGET', payload);
  },
  createDashboard: ({ commit, state, dispatch }) => {
    const newDashboard = {
      id: 'new',
      name: `${i18n.t('nav.newDashboard')} ${state.dashboards.length + 1}`,
      layout: [],
    };

    commit('CREATE_DASHBOARD', newDashboard);
    commit('SET_SELECTED_DASHBOARD', { selectedDashboardId: newDashboard.id });

    dispatch('setDashboardEditMode');
  },
  deleteDashboard: async ({ commit, state, dispatch }, { companyId, dashboardId }) => {
    try {
      commit('DELETE_DASHBOARD', dashboardId);

      await dashboardService.delete({ dashboardId, companyId });

      commit('SET_SELECTED_DASHBOARD', { selectedDashboardId: state.defaultDashboardId });
    } catch ({ message }) {
      console.error(message);
    } finally {
      await dispatch('fetchDashboards', { companyId });
    }
  },
  discardDashboard: ({ state, dispatch, commit }) => {
    dispatch('setDashboardEditMode');
    dispatch('setSelectedDashboard', state.defaultDashboardId);

    commit('DISCARD_NEW_DASHBOARD');
  },
  saveDashboard: async ({ dispatch, state }, { id, companyId, name, layout }) => {
    try {
      const cleanLayout = layout.map((item) => {
        if (!isNil(item.isDraggable)) delete item.isDraggable;
        if (!isNil(item.moved)) delete item.moved;

        return item;
      });

      if (isEqual(id, 'new')) {
        await dashboardService.create({
          companyId,
          name,
          layout: cleanLayout,
        });
      } else {
        await dashboardService.update({
          dashboardId: id,
          payload: {
            companyId,
            name,
            layout: cleanLayout,
          },
        });
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      dispatch('setDashboardEditMode');

      await dispatch('fetchDashboards', { companyId });

      if (isEqual(id, 'new')) {
        const lastDashboard = last(state.dashboards);

        dispatch('setSelectedDashboard', lastDashboard.id);
      }
    }
  },
};
