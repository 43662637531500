export default {
  SET_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
  SET_CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },
  SET_STATS: (state, payload) => {
    state.stats = payload;
  },
  SET_TOP_CUSTOMERS: (state, payload) => {
    state.topCustomers = payload;
  },
  SET_OVERDUE_CUSTOMERS: (state, payload) => {
    state.overdueCustomers = payload;
  },
  SET_COUNT_OVERDUE_CUSTOMERS: (state, payload) => {
    state.countOverdueCustomers = payload;
  },
  SET_PERCENTAGE_OVERDUE_CUSTOMERS: (state, payload) => {
    state.percentageOverdueCustomers = payload;
  },
  SET_SELECTED: (state, payload) => {
    state.selected = payload;
  },
};
