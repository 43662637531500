import { bankReconciliationService } from '@services';
import { isEmpty } from 'lodash-es';

export default {
  fetchTransactions: async ({ commit }, params = {}) => {
    try {
      const { data, total, accountIds } = await bankReconciliationService.list(params);

      commit('SET_BANK_TRANSACTIONS', data);
      commit('SET_TOTAL_BANK_TRANSACTIONS', total);
      commit('SET_ACCOUNT_IDS', accountIds);
    } catch (error) {
      console.warn(error);
    }
  },
  exportBankTransactions: async ({}, params = {}) => {
    try {
      return await bankReconciliationService.list({ ...params, contentType: 'xlsx' });
    } catch (error) {
      console.warn(error);
    }
  },
  updateTransaction: async ({}, params = {}) => {
    try {
      await bankReconciliationService.update(params);
    } catch (error) {
      console.warn(error);
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  fetchAccounts: async ({ commit }, params = {}) => {
    try {
      const { data, isReconnectNeeded } = await bankReconciliationService.accountList(params);

      if (!isEmpty(data)) {
        commit('SET_BANK_ACCOUNTS', data);
        commit('SET_IS_BANK_RECONNECT_NEEDED', isReconnectNeeded);
      }
    } catch (error) {
      console.warn(error);
    }
  },
  updateTableOptions: ({ commit }, payload) => {
    commit('SET_TABLE_OPTIONS', payload);
  },
  fetchAllocationSuggestions: async ({}, params = {}) => {
    try {
      return await bankReconciliationService.allocationSuggestions(params);
    } catch (error) {
      console.warn(error);
    }
  },
  updateAllocationSuggestionState: ({ commit }, params = {}) => {
    try {
      commit('UPDATE_ALLOCATION_SUGGESTION_STATE', params);
    } catch (error) {
      console.warn(error);
    }
  },
  resetTableOptions: ({ commit }) => {
    commit('RESET_TABLE_OPTIONS');
  },
};
