/* eslint-disable */
import { capitalize, isNil, isEqual } from 'lodash-es';
import Vue from 'vue';

import { BUSINESS_MODEL } from '@enums';
import store from '@store';

const { FREE } = BUSINESS_MODEL;

const grantPermissions = ({ action, possession, resource }) => {
  if (isNil(action) && isNil(possession) && isNil(resource)) return false;

  const { auth } = Vue;
  const { accessControlRoleIds } = auth?.user();
  const accessControl = store.getters['app/accessControl'];

  if (isNil(accessControl)) {
    localStorage.clear();

    Vue.router.go('/login');
  }

  return accessControl.can(accessControlRoleIds)[`${action}${capitalize(possession)}`](resource).granted;
};

const can = {
  install(Vue) {
    Vue.prototype.$can = {
      access({ action, possession, resource }) {
        return grantPermissions({ action, possession, resource });
      },
      freeAccess() {
        const businessModel = store.getters['settings/businessModel'];

        if (isEqual(businessModel, FREE)) {
          window.open('https://go.gaviti.com/lets-meet?=gaviti-dashboard', '_blank');

          return isEqual(businessModel, FREE);
        }

        return false;
      },
      enter({ action, possession, resource }, next) {
        if (!Vue.auth.check()) return next({ path: '/login' });

        if (!grantPermissions({ action, possession, resource })) return next({ path: '/li/invoices' });

        return next();
      },
      accessByRoleIds({ action, possession, resource, accessControlRoleIds }) {
        const accessControl = store.getters['app/accessControl'];

        return accessControl.can(accessControlRoleIds)[`${action}${capitalize(possession)}`](resource).granted;
      },
      support: () => (Vue.auth.check() && Vue.auth.user().isSupportUser) || false,
    };
  },
};

Vue.use(can);

export default can;
