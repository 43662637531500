import { isEqual, isEmpty, uniq, orderBy, isNil } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

import { appService, authService, customerService, invoiceService, companyService } from '@services';
import { COMPANY, PERMISSIONS, BUSINESS_MODEL } from '@enums';

const {
  RESOURCES: { WORKFLOWS, INSIGHTS },
  POSSESSIONS: { ANY },
  ACTIONS: { READ },
} = PERMISSIONS;

const { FREE } = BUSINESS_MODEL;

const {
  TYPES: { DEMO },
} = COMPANY;

const startOfMonthEightAM = () => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), 1, 8, 0, 0, 0).getTime();
};

const createDemoNotifications = () => {
  const notifications = [];

  const demoNotification = {
    userId: Vue.auth.user().id,
    status: 'upcoming',
    runNow: true,
    timestamp: startOfMonthEightAM(),
    type: 'chartCentral',
    isDemoNotification: true,
  };

  notifications.push(
    {
      ...demoNotification,
      id: uuidv4(),
      activityLog: {
        _id: uuidv4(),
        type: 'chartCentral',
        chart: 'totalAR',
        column: 'AR',
        sign: 'increase',
        percentage: 9,
      },
    },
    {
      ...demoNotification,
      id: uuidv4(),
      activityLog: {
        _id: uuidv4(),
        type: 'chartCentral',
        chart: 'totalAR',
        column: 'overdue',
        sign: 'decrease',
        percentage: 12,
      },
    },
    {
      ...demoNotification,
      id: uuidv4(),
      activityLog: {
        _id: uuidv4(),
        type: 'chartCentral',
        chart: 'pastDueCustomers',
        column: 'pastDueCustomers',
        sign: 'decrease',
        percentage: 14,
      },
    },
  );

  return notifications;
};

const addDemoNotifications = (notifications, demoNotifications) => {
  notifications.push(...demoNotifications);

  return orderBy(notifications, 'timestamp', 'desc');
};

const isDemoAdminUser = () => isEqual(Vue.auth.user().selectedCompany.type, DEMO);

export default {
  initAppStore: async ({ dispatch }, companyId) => {
    const promises = [
      dispatch('settings/fetchCompanySettings', { companyId }, { root: true }),
      dispatch('settings/loadCompanyUsers', { companyId }, { root: true }),
      dispatch('fetchLastDataUpdate', { companyId }),
      dispatch('settings/checkWorkflowConfigurations', { companyId }, { root: true }),
    ];

    if (Vue.prototype.$can.access({ action: READ, possession: ANY, resource: INSIGHTS })) {
      promises.push(dispatch('insights/fetchAppInsights', null, { root: true }));
    }

    await Promise.allSettled(promises);

    if (Vue.prototype.$can.access({ action: READ, possession: ANY, resource: WORKFLOWS })) {
      Promise.all([
        dispatch('workflowTemplates/getWorkflowTemplates', { companyId, type: 'workflows' }, { root: true }),
        dispatch('workflowTemplates/getWorkflowTemplates', { companyId, type: 'rules' }, { root: true }),
        dispatch('workflowTemplates/workflowTemplateDefaults', { companyId }, { root: true }),
      ]);
    }
  },
  fetchLastDataUpdate: async ({ commit }, { companyId }) => {
    try {
      const {
        lastDatasync: { invoices, customers, transactions },
      } = await companyService.fetchSpecificSettingsByCompanyId({ companyId, names: ['lastDatasync'] });

      commit('SET_LAST_DATA_UPDATE', [customers, invoices, transactions]);
    } catch ({ message }) {
      console.error(message);
    }
  },
  toggleDeviceOrientation: ({ commit }, payload) => {
    commit('SET_DEVICE_ORIENTATION', payload);
  },
  toggleIsDeviceMobile: ({ commit }, payload) => {
    commit('SET_IS_DEVICE_MOBILE', payload);
  },
  toggleSidebar: ({ commit }, payload) => {
    commit('SET_SIDEBAR', payload);

    localStorage.sidebar = payload;

    payload ? Vue.$gtag.event('sidenav_show') : Vue.$gtag.event('sidenav_hide');
  },
  toggleMenuButton: ({ commit }, payload) => {
    commit('SET_MENU_BUTTON', payload);
  },
  fetchAppNotifications: async ({ state, commit, rootGetters }, payload) => {
    try {
      const businessModel = rootGetters['settings/businessModel'];
      const { page, perPage } = payload;

      if (isEqual(page, 1)) {
        commit('SET_RESET_APP_NOTIFICATIONS');
      }

      const { appNotifications, demoAppNotifications } = state;

      const { list, stats } = await appService.fetchNotifications(payload);

      let notifications = appNotifications ? [...appNotifications, ...list] : [...list];

      if (isEqual(businessModel, FREE) || isDemoAdminUser()) {
        notifications = notifications.filter(({ type }) => !isEqual(type, 'alert'));
      }

      const customerIds = uniq(
        notifications
          .filter(({ activityLog }) => activityLog && activityLog?.customerId)
          .map(({ activityLog: { customerId } }) => customerId),
      );

      const invoiceIds = uniq(
        notifications
          .filter(({ activityLog }) => activityLog && activityLog?.invoiceId)
          .map(({ activityLog: { invoiceId } }) => invoiceId),
      );

      const promises = [];

      if (!isEmpty(customerIds)) {
        promises.push(
          customerService.list({
            companyId: payload.companyId,
            search: {
              ids: customerIds,
            },
          }),
        );
      }

      if (!isEmpty(invoiceIds)) {
        promises.push(
          invoiceService.list({
            companyId: payload.companyId,
            search: {
              ids: invoiceIds,
            },
          }),
        );
      }

      const [customersList, invoicesList] = await Promise.all(promises);

      if (isDemoAdminUser() && isEqual(page, 1)) {
        notifications = addDemoNotifications(notifications, demoAppNotifications);
      }

      if (isDemoAdminUser()) {
        notifications = notifications.filter(
          ({ type, activityLog }) => !isEqual(type, 'workflowEvent') && isNil(activityLog?.promiseToPayDate),
        );
      }

      commit('SET_CURRENT_PAGE', page);
      commit('SET_PAGES_COUNT', stats?.totalCount ? Math.ceil(Number(stats?.totalCount) / perPage) : 0);
      commit('SET_APP_NOTIFICATIONS', notifications);

      if (!isEmpty(customersList)) {
        commit('SET_APP_NOTIFICATIONS_CUSTOMERS', customersList.list);
      }

      if (!isEmpty(invoicesList)) {
        commit('SET_APP_NOTIFICATIONS_INVOICES', invoicesList.list);
      }
    } catch (error) {
      console.error(error);
    }
  },
  createDemoAppNotifications: ({ commit }) => {
    commit('SET_APP_DEMO_NOTIFICATIONS', createDemoNotifications());
  },
  fetchAppNotificationsCount: async ({ state, commit }, payload) => {
    try {
      let count = await appService.fetchNotificationsCount(payload);

      if (isDemoAdminUser()) {
        count += state.demoAppNotifications.filter((notification) => isEqual(notification.status, 'upcoming')).length;
      }

      commit('SET_APP_NOTIFICATIONS_COUNT', count);
    } catch (error) {
      console.error(error);
    }
  },
  updateAppNotificationStatus: async ({ commit }, payload) => {
    try {
      await appService.updateNotifications(payload);

      commit('SET_UPDATE_APP_NOTIFICATION_STATUS', {
        id: payload.notificationId,
      });
    } catch (error) {
      console.error(error);
    }
  },
  updateDemoAppNotification: async ({ commit }, id) => {
    try {
      commit('SET_UPDATE_APP_NOTIFICATION_STATUS', { id });
    } catch (error) {
      console.error(error);
    }
  },
  toggleShowModal: ({ commit }, payload) => {
    commit('SET_SHOW_MODAL', payload);
  },
  toggleShowAppDrawer: ({ commit, dispatch }, payload) => {
    const validPayload = ['mode', 'title', 'show'].every((k) => Object.keys(payload).includes(k));
    if (validPayload) {
      dispatch('setAppDrawerMode', payload.mode);
      dispatch('setAppDrawerTitle', payload.title);
      commit('SET_SHOW_APP_DRAWER', payload.show);
    } else if (!payload) {
      commit('SET_SHOW_APP_DRAWER', false);
    }
  },
  setAppDrawerMode: ({ commit }, payload) => {
    commit('SET_APP_DRAWER_MODE', payload);
  },
  setAppDrawerTitle: ({ commit }, payload) => {
    commit('SET_APP_DRAWER_TITLE', payload);
  },
  toggleIsAppNotificationsLoading: ({ commit }, payload) => {
    commit('SET_IS_APP_NOTIFICATIONS_LOADING', payload);
  },
  resetPassword: async (_, payload) => authService.updatePassword(payload),
  validateOtpToken: async (_, payload) => authService.validateOtp(payload),
  setAccessControl: ({ commit }, payload) => {
    commit('SET_ACCESS_CONTROL', payload);
  },
  setNewRulesDisplaySetting: ({ commit }, payload) => {
    commit('SET_SHOW_NEW_RULE_SETTING', payload);
  },
  toggleDarkMode: ({ commit }) => {
    commit('SET_DARK_MODE');
  },
  toggleConfetti: ({ commit }, payload) => {
    commit('TOGGLE_CONFETTI', payload);
  },
};
