import Vue from 'vue';

import { isEmpty, sortBy } from 'lodash-es';
import { httpClient } from '@api';

import { AllocationSuggestion } from './allocation-suggestion.dto';
import { BankReconciliation } from './bank-reconciliation.dto';
import { BankAccount } from './bank-account.dto';
import { BankChart } from './bank-chart.dto.js';

export default {
  list: async (params) => {
    try {
      let normalizedList = [];

      const response = await httpClient.post('bank-reconciliation/transaction/list', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });

      if (params?.contentType === 'xlsx') return response;

      const { data, total, accountIds = [] } = response;

      normalizedList = isEmpty(data) ? [] : data.map((item) => new BankReconciliation(item));

      return { data: normalizedList, total, accountIds };
    } catch (error) {
      console.warn(error);
    }
  },
  update: async (params) => {
    try {
      await httpClient.post('bank-reconciliation/transaction/update', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });
    } catch (error) {
      console.warn(error);
    }
  },
  accountList: async (params) => {
    try {
      let normalizedList = [];

      const { data, total, isReconnectNeeded } = await httpClient.post('bank-reconciliation/account/list', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = data.map((item) => new BankAccount(item));
      }

      return { data: normalizedList, total, isReconnectNeeded };
    } catch (error) {
      console.warn(error);
    }
  },
  allocationSuggestions: async (params) => {
    try {
      let normalizedList = [];

      const data = await httpClient.post('bank-reconciliation/transaction/suggestions', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = data.map((item) => new AllocationSuggestion(item));
      }

      return normalizedList;
    } catch (error) {
      console.warn(error);
    }
  },
  fetchBankChart: async (params) => {
    try {
      let normalizedList = [];

      const data = await httpClient.post('bank-reconciliation/transaction/chart', {
        companyId: Vue.prototype.$auth.user().selectedCompany.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = sortBy(
          data.map((item) => new BankChart(item)),
          ['dateParts.year', 'dateParts.month'],
        );
      }

      return normalizedList;
    } catch (error) {
      console.warn(error);
    }
  },
  generateDemo: async (params) => {
    return httpClient.post('bank-reconciliation/demo/generate', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
};
