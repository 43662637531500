import { isEqual, isEmpty } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

import { customerService, workflowService, insightsService } from '@services';
import { COMPANY } from '@enums';

const {
  TYPES: { DEMO },
} = COMPANY;

const startOfMonthEightAM = () => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), 1, 8, 0, 0, 0).getTime();
};

const createDemoInsights = async (companyId) => {
  const insights = [];

  const { list } = await customerService.list({
    companyId,
    type: 'top',
    sort: { 'stats.totalLocalBalance': -1 },
    page: 1,
    perPage: 3,
  });

  const [workflow] = await workflowService.list({
    companyId,
    where: {
      type: 'workflow',
      deleted: false,
    },
  });

  const demoInsight = {
    userId: Vue.auth.user().id,
    status: 'upcoming',
    runNow: true,
    timestamp: startOfMonthEightAM(),
    type: 'insights',
    isDemoInsight: true,
  };

  insights.push(
    {
      ...demoInsight,
      id: uuidv4(),
      insight: {
        _id: uuidv4(),
        type: 'dso',
        sign: 'decrease',
        percentage: 20,
        workflow,
      },
    },
    {
      ...demoInsight,
      id: uuidv4(),
      insight: {
        _id: uuidv4(),
        type: 'workflow',
        percentage: 20,
        workflow,
      },
    },
    {
      ...demoInsight,
      id: uuidv4(),
      insight: {
        _id: uuidv4(),
        type: 'cashflow',
        sign: 'increase',
        percentage: 30,
        workflow,
        list,
      },
    },
  );

  return insights;
};

export default {
  fetchAppInsights: async ({ dispatch }) => {
    try {
      await dispatch('setupAppInsights');
    } catch (error) {
      console.error(error);
    }
  },
  setupAppInsights: async ({ commit, state }) => {
    const isDemo = isEqual(Vue.auth.user().selectedCompany.type, DEMO);

    const hasDemoInsights = !isEmpty(state.demoAppInsights);

    if (isDemo && !hasDemoInsights) {
      const companyId = Vue.auth.user().selectedCompany.id;
      const insights = await createDemoInsights(companyId);

      commit('SET_APP_DEMO_INSIGHTS', insights);
    }

    const insights = await insightsService.list();

    if (!isEmpty(insights)) {
      commit('SET_APP_INSIGHTS', insights);
    }
  },
  fetchInsightsCount: async ({ state, commit }) => {
    try {
      const isDemo = isEqual(Vue.auth.user().selectedCompany.type, DEMO);

      if (isDemo) {
        const count = state.demoAppInsights.filter((insight) => isEqual(insight.status, 'upcoming')).length;

        commit('SET_DEMO_APP_INSIGHTS_COUNT', count);
      }

      const count = await insightsService.count();

      commit('SET_APP_INSIGHTS_COUNT', count);
    } catch (error) {
      console.error(error);
    }
  },
  updateInsightStatus: async ({ dispatch }, id) => {
    await Promise.all([insightsService.update([id]), dispatch('setupAppInsights'), dispatch('fetchInsightsCount')]);
  },
  updateDemoInsightStatus: ({ commit }, id) => {
    commit('SET_UPDATE_DEMO_APP_INSIGHT_STATUS', { id });
  },
  toggleIsAppInsightsLoading: ({ commit }, payload) => {
    commit('SET_IS_APP_INSIGHTS_LOADING', payload);
  },
};
