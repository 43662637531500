import { isEmpty, isEqual, isFunction, isNil, isString, snakeCase } from 'lodash-es';
import VueGtag from 'vue-gtag';

import { DICTIONARY } from '@enums';
import router from '@router';

const { MODE } = import.meta.env;
const isProduction = isEqual(MODE, 'production');

const validate = (key, type, dictionary) => {
  if (!key || !dictionary[key]) {
    console.warn(
      `[Vue-Analytics] A valid ${type} is required. Valid options are: ${Object.values(dictionary).join(', ')}.`,
    );

    return false;
  }

  return true;
};

const toSnakeCase = (str) => {
  const parts = str.split(/(:|;)/);
  const snakeCaseParts = parts.map((part, index) => (part === ':' || part === ';' ? part : snakeCase(part)));

  return snakeCaseParts.join('');
};

const VueAnalytics = {
  install(Vue, { id, debug = false }) {
    if (!isFunction(VueGtag)) {
      console.warn(`[Vue-Analytics] VueGtag is required.`);

      return;
    }

    if (!id) {
      console.warn(`[Vue-Analytics] A valid Google Analytics Measurement ID is required.`);

      return;
    }

    const config = {
      enabled: isProduction,
      deferScriptLoad: true,
      pageTrackerTemplate(to) {
        return {
          page_title: to.name,
          page_path: to.path,
        };
      },
      config: {
        id,
      },
    };

    Vue.use(VueGtag, config, router);

    const { methods, categories, actions, types, elements } = DICTIONARY;

    Vue.prototype.$VueAnalytics = {
      track({ method = 'event', category, action, type, label, element, dateRange, value, ...params }) {
        const validations = [
          validate(method, 'method', methods),
          validate(category, 'category', categories),
          validate(action, 'action', actions),
          validate(type, 'type', types),
        ];

        if (!validations.every(Boolean)) return;

        const eventMethod = toSnakeCase(methods[method]);
        const eventCategory = toSnakeCase(categories[category]);
        const eventAction = toSnakeCase(actions[action]);
        const eventType = toSnakeCase(types[type]);

        let eventName = `${eventCategory}_${eventAction}`;

        if (!isNil(label) && isString(label) && !isEmpty(label)) {
          const eventLabel = toSnakeCase(label);

          eventName += `_${eventLabel}`;
          params.label = eventLabel;
        }

        if (!isNil(element) && !isEmpty(element)) {
          const isValid = validate(element, 'element', elements);

          if (!isValid) return;

          params.element = toSnakeCase(elements[element]);
        }

        const payload = {
          category: eventCategory,
          action: eventAction,
          type: eventType,
          ...params,
        };

        if (!isNil(dateRange)) {
          payload.dateRange = dateRange;
        }

        if (!isNil(value)) {
          payload.value = value;
        }

        Object.keys(payload).forEach((key) => {
          const snakeCaseKey = toSnakeCase(key);

          if (!isEqual(snakeCaseKey, key)) {
            payload[snakeCaseKey] = payload[key];

            delete payload[key];
          }
        });

        if (debug) {
          console.info(`[Vue-Analytics (enabled: ${config.enabled})] ${eventName}`, payload);
        }

        Vue.$gtag[eventMethod](eventName, payload);
      },
    };
  },
};

export default VueAnalytics;
