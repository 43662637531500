import { isEqual } from 'lodash-es';
import Vue from 'vue';

const demoCompanyData = {
  timeFilter: {
    closedInvoicesTotalAmount: 6832551,
    workflowsActivities: {
      count: 1023,
      customers: 553,
    },
    notesActivities: {
      count: 743,
      customers: 301,
    },
    manualNotesActivities: {
      count: 274,
      customers: 225,
    },
  },
  dso: 67,
  trends: {
    totalAr: 6,
    countOutstandingInvoices: -4,
    countCustomersOverdue: -7,
    dso: -8,
    dsoStandard: -5,
    add: -9,
    mdd: -10,
    collectionRate: 15,
  },
  cei: 84,
  dsoStandard: 89,
  add: 22,
  mdd: 56,
  collectionRate: {
    calculationValues: {
      expectedCollected: 19_000_000,
      collected: 15_000_000,
    },
    value: 80,
  },
  manualTimeSaved: {
    value: 1530,
    startCountDate: 1641765600000,
  },
};

export default {
  closedInvoicesTotalAmount: ({ closedInvoicesTotalAmount }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.timeFilter.closedInvoicesTotalAmount
      : closedInvoicesTotalAmount,
  workflowsActivities: ({ workflowsActivities }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.timeFilter.workflowsActivities
      : workflowsActivities,
  notesActivities: ({ notesActivities }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.timeFilter.notesActivities
      : notesActivities,
  manualNotesActivities: ({ manualNotesActivities }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.timeFilter.manualNotesActivities
      : manualNotesActivities,
  dso: ({ dso }) => (isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? { value: demoCompanyData.dso } : dso),
  cei: ({ cei }) => (isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.cei / 100 : cei / 100),
  dsoStandard: ({ dsoStandard }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? { value: demoCompanyData.dsoStandard } : dsoStandard,
  add: ({ add }) => (isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? { value: demoCompanyData.add } : add),
  mdd: ({ mdd }) => (isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? { value: demoCompanyData.mdd } : mdd),
  dsoTrend: ({ trends: { dso } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.dso : dso,
  dsoStandardTrend: ({ trends: { dsoStandard } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.dsoStandard : dsoStandard,
  addTrend: ({ trends: { add } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.add : add,
  mddTrend: ({ trends: { mdd } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.mdd : mdd,
  collectionRateTrend: ({ trends: { collectionRate } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.collectionRate : collectionRate,
  totalArTrend: ({ trends: { totalAr } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.trends.totalAr : totalAr,
  outstandingInvoices: ({ outstandingInvoices }) => outstandingInvoices,
  countOutstandingInvoicesTrend: ({ trends: { countOutstandingInvoices } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.trends.countOutstandingInvoices
      : countOutstandingInvoices,
  countCustomersOverdueTrend: ({ trends: { countCustomersOverdue } }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo')
      ? demoCompanyData.trends.countCustomersOverdue
      : countCustomersOverdue,
  loadingWidgets: ({ loadingWidgets }) => loadingWidgets,
  filterWidgets: ({ filterWidgets }) => filterWidgets,
  collectionRate: ({ collectionRate }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.collectionRate : collectionRate,
  manualTimeSaved: ({ manualTimeSaved }) =>
    isEqual(Vue.auth.user().selectedCompany.type, 'demo') ? demoCompanyData.manualTimeSaved : manualTimeSaved,
};
