import { convertToHours } from '@utils';

export default {
  SET_DSO: (state, payload) => {
    state.dso = payload;
  },
  SET_CEI: (state, payload) => {
    state.cei = payload;
  },
  SET_DSO_STANDARD: (state, payload) => {
    state.dsoStandard = payload;
  },
  SET_ADD: (state, payload) => {
    state.add = payload;
  },
  SET_MDD: (state, payload) => {
    state.mdd = payload;
  },
  SET_TREND: (state, { name, value }) => {
    state.trends[name] = value;
  },
  SET_CLOSED_INVOICES_TOTAL_AMOUNT: (state, payload) => {
    state.closedInvoicesTotalAmount = payload;
  },
  SET_WORKFLOW_ACTIVITIES: (state, payload) => {
    state.workflowsActivities = payload;
  },
  SET_NOTES_ACTIVITIES: (state, payload) => {
    state.notesActivities = payload;
  },
  SET_MANUAL_NOTES_ACTIVITIES: (state, payload) => {
    state.manualNotesActivities = payload;
  },
  SET_LOADING_WIDGETS: (state, { widgetId, value }) => {
    state.loadingWidgets[widgetId] = value;
  },
  SET_OUTSTANDING_INVOICES: (state, payload) => {
    state.outstandingInvoices = payload;
  },
  SET_FILTER_WIDGETS: (state, { widgetId, value }) => {
    state.filterWidgets[widgetId] = value;
  },
  SET_COLLECTION_RATE: (state, payload) => {
    state.collectionRate = payload;
  },
  SET_MANUAL_TIME_SAVED: (state, payload) => {
    const { startCountDate } = payload;

    state.manualTimeSaved = {
      value: convertToHours(payload),
      startCountDate,
    };
  },
};
