import { isEmpty, isEqual, isNil } from 'lodash-es';
import Vue from 'vue';

import { workflowService, invoiceService } from '@services';
import { i18n } from '@plugins';

export default {
  fetchWorkflowsData: async ({ commit }, { companyId }) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const fetchWorkflowsPromise = workflowService.list({
        companyId,
        where: {
          type: 'workflow',
          deleted: false,
        },
      });
      const fetchAllowedTagsPromises = ['emailBody', 'emailSubject', 'smsBody'].map((type) =>
        workflowService.listTags({ companyId, type }),
      );

      const [workflows, emailBody, emailSubject, smsBody] = await Promise.all([
        fetchWorkflowsPromise,
        ...fetchAllowedTagsPromises,
      ]);

      commit('FETCH_WORKFLOWS_LIST', workflows);
      commit('FETCH_ALLOWED_TAGS', { emailBody, emailSubject, smsBody });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  fetchWorkflowsList: async ({ commit }, { companyId }) => {
    try {
      const workflows = await workflowService.list({
        companyId,
        where: {
          type: 'workflow',
          deleted: false,
        },
      });

      commit('FETCH_WORKFLOWS_LIST', workflows);
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteWorkflowsData: async ({ commit, rootGetters: { defaultWorkflowId } }, { companyId, workflowsIds }) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      if (workflowsIds.some((workflowId) => isEqual(defaultWorkflowId, workflowId))) {
        return;
      }

      await Promise.all(
        workflowsIds.map((workflowId) =>
          workflowService.update({
            companyId,
            workflowId,
            workflow: { deleted: true, deletedAt: Date.now() },
          }),
        ),
      );

      commit('DELETE_WORKFLOWS', workflowsIds);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  copyWorkflowData: async ({ commit }, { companyId, workflowId }) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const workflow = await workflowService.duplicate({
        companyId,
        workflowId,
      });

      commit('COPY_WORKFLOW', workflow);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  upsertWorkflowData: async ({ commit }, payload) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const {
        companyId,
        workflow: { id: workflowId },
        workflow,
      } = payload;

      if (isEmpty(workflowId)) {
        const newWorkflow = await workflowService.create({
          companyId,
          workflow,
        });

        commit('INSERT_WORKFLOWS', { newWorkflows: [newWorkflow] });

        return newWorkflow;
      }

      await workflowService.update({
        companyId,
        workflowId,
        workflow,
      });

      commit('UPDATE_WORKFLOW', { workflowId, workflow });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  upsertWorkflowRuleData: async ({ commit }, payload) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const {
        companyId,
        workflowId,
        workflowRule: { id: workflowRuleId },
        workflowRule,
      } = payload;

      if (isEmpty(workflowRuleId)) {
        const {
          workflowRule: { deliveryType, recipient, trigger },
        } = payload;
        const newWorkflowRule = await workflowService.createRule({
          companyId,
          workflowId,
          workflowRule,
          deliveryType,
          recipient,
          trigger,
        });

        commit('INSERT_WORKFLOW_RULE', {
          workflowId,
          workflowRule: newWorkflowRule,
        });

        return newWorkflowRule;
      }

      let override = false;

      if (!isNil(workflowRule.override)) {
        override = workflowRule.override;

        delete workflowRule.override;
      }

      const id = await workflowService.updateRule({
        companyId,
        workflowId,
        workflowRuleId,
        workflowRule,
        override,
      });

      workflowRule.id = id;

      commit('UPDATE_WORKFLOW_RULE', { workflowId, workflowRule });
    } catch ({ message }) {
      throw new Error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  runWorkflowRuleData: async ({ commit, dispatch }, payload) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      await workflowService.run(payload);

      await dispatch(
        'settings/fetchAndUpdateCompanySetting',
        {
          companyId: Vue.auth.user().selectedCompany.id,
          name: 'creditFormUsage',
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
  fetchTotalLocalBalance: async ({ commit }, payload) => {
    const res = await invoiceService.fetchTotalLocalBalance(payload);

    commit('FETCH_TOTAL_LOCAL_BALANCE', res);
  },
  fetchCountInvoiceOutstanding: async ({ commit }, payload) => {
    const res = await invoiceService.fetchCountOutstanding(payload);

    commit('FETCH_INVOICE_COUNT_OUTSTANDING', res);
  },
  downloadExcel: async (_, payload) => {
    try {
      return await workflowService.export(payload);
    } catch (error) {
      console.error(error);
    }
  },
  uploadWorkflowTemplate: async ({ commit }, { formData }) => {
    try {
      const newWorkflows = await workflowService.upload(formData);

      commit('INSERT_WORKFLOWS', { newWorkflows });

      return { success: true };
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', `${i18n.t('workflow.modal.workflowImportFailure')}:<br>${message}`);

      return { success: false };
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  calculateStats: async ({ commit }, payload) => {
    try {
      const res = await workflowService.stats(payload);

      commit('SET_STATS', res);
    } catch (error) {
      console.error(error);
    }
  },
  calculateRuleStats: async ({ commit }, payload) => {
    try {
      const res = await workflowService.ruleStats(payload);

      commit('SET_RULE_STATS', res);
    } catch (error) {
      console.error(error);
    }
  },
  fetchWorkflowTags: async ({ commit }, { companyId }) => {
    try {
      commit('settings/LOADING_STATUS', true, { root: true });

      const [emailBody, emailSubject, smsBody] = await Promise.all(
        ['emailBody', 'emailSubject', 'smsBody'].map((type) => workflowService.listTags({ companyId, type })),
      );

      commit('FETCH_ALLOWED_TAGS', { emailBody, emailSubject, smsBody });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('settings/LOADING_STATUS', false, { root: true });
    }
  },
};
