import Vue from 'vue';

import { AUTH } from '@enums';

const turnstile = {
  install(Vue) {
    Vue.prototype.$turnstile = {
      setup() {
        const script = document.createElement('script');

        script.src = AUTH.VERIFICATION_METHODS.TURNSTILE.SRC;
        script.async = true;
        script.defer = true;

        document.head.appendChild(script);
      },
    };
  },
};

Vue.use(turnstile);

export default turnstile;
