export default {
  SET_ACCESS_CONTROL: (state, payload) => {
    state.accessControl = payload;
  },
  SET_ACCESS_CONTROL_ROLE_IDS: (state, payload) => {
    state.accessControlRoleIds = payload;
  },
  SET_SECURITY_POLICY: (state, payload) => {
    state.securityPolicy = payload;
  },
};
