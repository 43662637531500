import { isEqual } from 'lodash-es';
import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const reqInterceptor = (req) => {
  const {
    data: { contentType = 'application/json' } = {
      contentType: 'application/json',
    },
  } = req;

  const token = localStorage.getItem('GAV_accessToken');

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  if (!isEqual(contentType, 'application/json')) {
    req.responseType = 'blob';

    if (isEqual(contentType, 'link-xlsx')) {
      req.responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
  }

  if (isEqual(req.data?.constructor?.name?.toLowerCase(), 'formdata')) {
    req.headers['Content-Type'] = 'multipart/form-data';
  }

  return req;
};

const resInterceptor = ({
  data,
  data: { success, errorMsg, data: dataJson },
  headers: { 'content-type': contentType },
}) => {
  if (!contentType.startsWith('application/json')) {
    return {
      data,
      contentType,
    };
  }

  if (!success) {
    throw new Error(errorMsg);
  }

  return dataJson;
};

httpClient.interceptors.request.use(reqInterceptor);
httpClient.interceptors.response.use(resInterceptor);

export default httpClient;
