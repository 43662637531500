import Vue from 'vue';

import { LAYOUTS, PERMISSIONS } from '@enums';

const {
  RESOURCES: { INTEGRATIONS, USERS, PARTNERS },
  POSSESSIONS: { ANY },
  ACTIONS: { READ, UPDATE },
} = PERMISSIONS;

const canEnter = (permissions, next) => Vue.prototype.$can.enter(permissions, next);
const freeAccess = () => Vue.prototype.$can.freeAccess();

export default [
  {
    name: 'company-preferences',
    path: '/li/settings/company-preferences',
    meta: {
      title: 'settings.preferences',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
    },
    component: () => import('@views/Settings/Preferences/index'),
  },
  {
    name: 'integration-list',
    path: '/li/settings/integration-list',
    meta: {
      title: 'settings.manageRoles.resources.integrations',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
    },
    component: () => import('@views/Settings/Integrations/index'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: ANY, resource: INTEGRATIONS }, next);
    },
  },
  {
    name: 'users',
    path: '/li/settings/users',
    meta: {
      title: 'settings.users',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
    },
    component: () => import('@views/Settings/Users/index'),
    beforeEnter(_to, _from, next) {
      return canEnter({ action: READ, possession: ANY, resource: USERS }, next);
    },
  },
  {
    name: 'partners',
    path: '/li/settings/partners',
    meta: {
      title: 'settings.partners',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
      nonFree: true,
    },
    component: () => import('@views/Settings/Partners/index'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: UPDATE, possession: ANY, resource: PARTNERS }, next);
    },
  },
  {
    name: 'back-office',
    path: '/li/settings/back-office',
    meta: {
      title: 'Back Office',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
    },
    component: () => import('@views/Settings/BackOffice/index'),
    beforeEnter(_to, _from, next) {
      if (!Vue.prototype.$can.support()) {
        return next({ path: '/li/dashboard' });
      }

      return next();
    },
  },
  {
    name: 'mailer',
    path: '/li/settings/mailer',
    meta: {
      title: 'Mailer',
      layout: LAYOUTS.MAIN,
      isSettings: true,
      auth: true,
      nonFree: true,
    },
    component: () => import('@views/Settings/Mailer/index'),
    beforeEnter(_to, _from, next) {
      return !freeAccess() && canEnter({ action: UPDATE, possession: ANY, resource: PARTNERS }, next);
    },
  },
];
