import { httpClient } from '@api';

import { ActivityLog } from './activityLog.dto';

export default {
  createNote: async (payload) => httpClient.post('activity-log/note', payload),
  markAsDone: async (payload) => httpClient.post('activity-log/action/mark-done', payload),
  findById: async (payload) => httpClient.post('activity-log/findById', payload),
  createDemo: async (payload) => httpClient.post('activity-log/demo/create', payload),
  cleanDemo: async (payload) => httpClient.post('activity-log/demo/clean-up', payload),
  clearInvoicesPayment: async (payload) => httpClient.post('activity-log/demo/clear-payment', payload),
  count: async (payload) => httpClient.post('activity-log/count', payload),
  sendReplyEmail: async (payload) => httpClient.post('mail/reply', payload),
  async list(payload) {
    try {
      const response = await httpClient.post('activity-log/list', payload);
      if (payload?.contentType === 'xlsx') return response;

      const { list, stats } = response;
      const normalizedList = list.map((item) => new ActivityLog(item));
      return { list: normalizedList, stats };
    } catch (err) {
      console.warn(err);
    }
  },
  async actionsList(payload) {
    try {
      const { list, stats } = await httpClient.post('activity-log/actions/list', payload);
      const normalizedList = list.map((item) => new ActivityLog(item));
      return { list: normalizedList, stats };
    } catch (err) {
      console.warn(err);
    }
  },
};
