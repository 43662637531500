import { isEqual } from 'lodash-es';
import { httpClient } from '@api';

import { Customer } from './customer.dto';

export default {
  update: async (payload) => httpClient.post('customers/update', payload),
  delete: async (payload) => httpClient.post('customers/delete', payload),
  fetchCustomersChart: async (payload) => httpClient.post('customers/chart', payload),
  fetchCountOverdue: async (payload) => httpClient.post('customers/count-overdue', payload),
  fetchCustomersRisk: async (payload) => httpClient.post('customers/count-risk', payload),
  fetchMetrics: async (payload) => httpClient.post('customers/metrics', payload),
  fetchCustomersIdsWithOpenInvoices: async (payload) => httpClient.post('customers/ids-with-open-invoices', payload),
  fetchCustomersIds: async (payload) => httpClient.post('customers/ids', payload),
  getNonExistingIds: async (payload) => httpClient.post('customers/non-existing-ids', payload),
  async list(payload) {
    try {
      const data = await httpClient.post('customers/list', payload);

      if (isEqual(payload.contentType, 'link-xlsx')) {
        return data;
      }

      const { list, stats } = data;
      const normalizedList = list.map((item) => new Customer(item));
      return { list: normalizedList, stats };
    } catch (err) {
      console.warn(err);
    }
  },
};
