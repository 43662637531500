import VuexPersist from 'vuex-persist';
import CryptoJS from 'crypto-js';
import CookieJS from 'js-cookie';

const isProduction = import.meta.env.MODE === 'production';
const cookieName = '__gav_c.n';
const cookieSalt = '__gav_c.s';
const storageKey = '__gav_s.k';

const generateRandomToken = (keySize = 128 / 8) => {
  return CryptoJS.lib.WordArray.random(keySize).toString();
};

const encryptionToken = CookieJS.get(cookieName) || generateRandomToken();
const encryptionSalt = CookieJS.get(cookieSalt) || generateRandomToken();

CookieJS.set(cookieName, encryptionToken, { secure: true, expires: 30 });
CookieJS.set(cookieSalt, encryptionSalt, { secure: true, expires: 30 });

const derivedKey = CryptoJS.PBKDF2(encryptionToken, encryptionSalt, {
  keySize: 128 / 8,
  iterations: 1000,
  hash: CryptoJS.algo.SHA256,
}).toString();

const encryptedStorage = () => {
  return {
    getItem: () => {
      const store = window.localStorage.getItem(storageKey);

      if (store) {
        try {
          const bytes = CryptoJS.AES.decrypt(store, derivedKey);

          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
          window.localStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      const store = CryptoJS.AES.encrypt(value, derivedKey).toString();

      return window.localStorage.setItem(storageKey, store);
    },
    removeItem: () => {
      if (window.localStorage.getItem(storageKey)) {
        window.localStorage.removeItem(storageKey);
      }
    },
  };
};

const vuexLocal = new VuexPersist({
  key: storageKey,
  storage: isProduction ? encryptedStorage() : window.localStorage,
  reducer: ({ auth }) => ({
    auth: { securityPolicy: auth.securityPolicy },
  }),
});

export default vuexLocal.plugin;
