const DICTIONARY = {
  methods: {
    event: 'event',
  },
  categories: {
    select: 'select',
    insights: 'insights',
    detail_view: 'detail_view',
    detail_edit: 'detail_edit',
    transaction_allocation: 'transaction_allocation',
    dashboard_actions: 'dashboard_actions',
    send_form_to_prospect: 'send_form_to_prospect',
    credit_report: 'credit_report',
  },
  actions: {
    selected: 'selected',
    deselected: 'deselected',
    clicked: 'clicked',
    expanded: 'expanded',
    collapsed: 'collapsed',
    shown: 'shown',
    edited: 'edited',
    submitted: 'submitted',
    response: 'response',
  },
  elements: {
    checkbox: 'checkbox',
    menu: 'menu',
    icon: 'icon',
    link: 'link',
    button: 'button',
    side_panel: 'side_panel',
    toolbar_menu: 'toolbar_menu',
    form: 'form',
    form_dropdown: 'form:dropdown',
    radio_buttons: 'radio_buttons',
    dropdown: 'dropdown',
    component: 'component',
  },
  types: {
    table: 'table',
    side_panel: 'side_panel',
    page: 'page',
    form: 'form',
    toolbar: 'toolbar',
  },
};

Object.freeze(DICTIONARY);

export default DICTIONARY;
