import { authService } from '@services';

export default {
  ssoFetchEntryPoint: async (_, payload) => {
    try {
      return await authService.fetchSsoEntryPoint(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  ssoLogin: async (_, payload) => {
    try {
      return await authService.ssoLogin(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  setSecurityPolicy: ({ commit }, payload) => {
    commit('SET_SECURITY_POLICY', payload);
  },
};
