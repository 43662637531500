import { isEmpty, isNil, sortBy, get, isEqual } from 'lodash-es';
import { subDays, endOfDay } from 'date-fns';
import Vue from 'vue';

import { companyService, invoiceService, activityService, customerService } from '@services';

const startOfDayUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth(), now.getDate());
};

const startOfPreviousMonthUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth() - 1, 1);
};

const endOfDayUtc = (timestamp) => {
  const date = new Date(timestamp);

  return endOfDay(date).getTime();
};

const startOfMonthUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth(), 1);
};

const startOfTwoMonthsAgoUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth() - 2, 1);
};

const startOfXMonthsAgoUtc = (x) => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth() - x, 1);
};

const thirtyDaysAgo = () => subDays(startOfDayUtc(), 30).getTime();

const lastDayOfPreviousMonth = () => {
  const now = new Date();

  return Date.UTC(now.getFullYear(), now.getMonth(), 1) - 1;
};

const lastDayOfMonth = () => {
  const now = new Date();

  return Date.UTC(now.getFullYear(), now.getMonth() + 1, 1) - 1;
};

const previousMonth = () => [startOfPreviousMonthUtc(), lastDayOfPreviousMonth()];

const currentMonth = () => [startOfMonthUtc(), lastDayOfMonth()];

const getMetricPercentage = ({ currentValue, pastValue }) => {
  try {
    if (!isEqual(pastValue, 0)) {
      const total = currentValue - pastValue;

      return (total / pastValue) * 100;
    }

    return 0;
  } catch ({ message }) {
    console.error(message);
  }
};

export default {
  fetchMonthlyDSO: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      const res = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        const { value, totalAr, totalSales } = res[0];

        commit('SET_DSO', { value, totalAr, totalSales });
      } else {
        commit('SET_DSO', { value: 'N/A' });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMonthlyCEI: async ({ commit }, payload) => {
    try {
      const res = await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        commit('SET_CEI', res[0].value);
      } else {
        commit('SET_CEI', 'N/A');
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMonthlyDSOStandard: async ({ commit }, payload) => {
    try {
      const res = await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        const { value, totalAr, totalSales } = res[0];

        commit('SET_DSO_STANDARD', { value, totalAr, totalSales });
      } else {
        commit('SET_DSO_STANDARD', { value: 'N/A' });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMonthlyADD: async ({ commit }, payload) => {
    try {
      const res = await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        commit('SET_ADD', res[0]);
      } else {
        commit('SET_ADD', { value: 'N/A' });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMonthlyMDD: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      const res = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        commit('SET_MDD', res[0]);
      } else {
        commit('SET_MDD', { value: 'N/A' });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchTrend: async ({ commit }, payload) => {
    try {
      const dateRange = [thirtyDaysAgo(), startOfDayUtc()];

      const [pastValue, currentValue] = await Promise.all([
        companyService.fetchMetric({
          ...payload,
          dateRange: undefined,
          timestamp: dateRange[0],
        }),
        companyService.fetchMetric({
          ...payload,
          dateRange: undefined,
          timestamp: dateRange[1],
        }),
      ]);

      if (!isEmpty(pastValue) && !isEmpty(currentValue)) {
        commit('SET_TREND', {
          name: payload.name,
          value: getMetricPercentage({
            pastValue: pastValue[0].value,
            currentValue: currentValue[0].value,
          }),
        });
      } else {
        commit('SET_TREND', {
          name: payload.name,
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchDSOTrend: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      let values = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(values)) {
        values = sortBy(values, 'timestamp');

        commit('SET_TREND', {
          name: 'dso',
          value: getMetricPercentage({
            pastValue: get(values[0], 'value', 0),
            currentValue: get(values, 'value', 0),
          }),
        });
      } else {
        commit('SET_TREND', {
          name: 'dso',
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchDSOStandardTrend: async ({ commit }, payload) => {
    try {
      let values = await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(values)) {
        values = sortBy(values, 'timestamp');

        commit('SET_TREND', {
          name: 'dsoStandard',
          value: getMetricPercentage({
            pastValue: get(values[0], 'value', 0),
            currentValue: get(values[1], 'value', 0),
          }),
        });
      } else {
        commit('SET_TREND', {
          name: 'dsoStandard',
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMDDTrend: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      let values = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(values)) {
        values = sortBy(values, 'timestamp');

        commit('SET_TREND', {
          name: 'mdd',
          value: getMetricPercentage({
            pastValue: get(values[0], 'value', 0),
            currentValue: get(values, 'value', 0),
          }),
        });
      } else {
        commit('SET_TREND', {
          name: 'mdd',
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchADDTrend: async ({ commit }, payload) => {
    try {
      let values = await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(values)) {
        values = sortBy(values, 'timestamp');

        commit('SET_TREND', {
          name: 'add',
          value: getMetricPercentage({
            pastValue: get(values[0], 'value', 0),
            currentValue: get(values[1], 'value', 0),
          }),
        });
      } else {
        commit('SET_TREND', {
          name: 'add',
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCollectionRateTrend: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      let values = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(values)) {
        values = sortBy(values, 'timestamp');

        commit('SET_TREND', {
          name: 'collectionRate',
          value: getMetricPercentage({
            pastValue: get(values[0], 'value', 0),
            currentValue: get(values[1], 'value', 0),
          }),
        });
      } else {
        commit('SET_TREND', {
          name: 'collectionRate',
          value: undefined,
        });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchClosedInvoicesData: async ({ commit }, payload) => {
    try {
      const { closedInvoicesTotalAmount } = await invoiceService.fetchCountClosed(payload);

      commit('SET_CLOSED_INVOICES_TOTAL_AMOUNT', !isNil(closedInvoicesTotalAmount) ? closedInvoicesTotalAmount : 0);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchWorkflowsActivitiesCount: async ({ commit }, payload) => {
    try {
      const { workflowActivities } = await activityService.count(payload);

      commit('SET_WORKFLOW_ACTIVITIES', workflowActivities);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchNotesActivitiesCount: async ({ commit }, payload) => {
    try {
      const { notesActivities } = await activityService.count(payload);

      commit('SET_NOTES_ACTIVITIES', notesActivities);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchManualNotesActivitiesCount: async ({ commit }, payload) => {
    try {
      const { manualNotesActivities } = await activityService.count(payload);

      commit('SET_MANUAL_NOTES_ACTIVITIES', manualNotesActivities);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchMonthlyCollectionRate: async ({ commit }, payload) => {
    try {
      const { customerId } = payload;

      const res = !isNil(customerId)
        ? await customerService.fetchMetrics(payload)
        : await companyService.fetchMetricMeasurements(payload);

      if (!isEmpty(res)) {
        commit('SET_COLLECTION_RATE', res[0]);
      } else {
        commit('SET_COLLECTION_RATE', 'N/A');
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchManualTimeSaved: async ({ commit }) => {
    try {
      const [res] = await companyService.fetchMetric({
        companyId: Vue.auth.user().selectedCompany.id,
        timestamp: Date.now(),
        name: 'manualTimeSaved',
      });

      if (!isEmpty(res) && !isEmpty(res?.value)) {
        commit('SET_MANUAL_TIME_SAVED', res?.value);
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  getAgingBucketsWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'agingBuckets',
        value: true,
      });

      await dispatch(
        'charts/fetchAgingBucketsChart',
        {
          companyId,
          users,
          search,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'agingBuckets',
        value: false,
      });
    }
  },
  getTermsOfPaymentsWidget: async ({ commit, dispatch }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'termsOfPayments',
        value: true,
      });

      await dispatch(
        'charts/fetchTermsOfPaymentsChart',
        {
          name: 'termsOfPayments',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfPreviousMonthUtc(), startOfPreviousMonthUtc()],
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'termsOfPayments',
        value: false,
      });
    }
  },
  getTotalARWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'totalAR',
        value: true,
      });

      await Promise.all([
        dispatch(
          'invoices/fetchTotalARBalance',
          {
            companyId,
            users,
            search,
          },
          { root: true },
        ),
        dispatch('fetchTrend', {
          companyId,
          name: 'totalAr',
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'totalAR',
        value: false,
      });
    }
  },
  getOutstandingInvoicesWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoices',
        value: true,
      });

      const [outstandingInvoices] = await Promise.all([
        invoiceService.fetchCountOutstanding({
          companyId,
          users,
          search,
        }),
        dispatch('fetchTrend', {
          companyId,
          name: 'countOutstandingInvoices',
        }),
      ]);

      commit('SET_OUTSTANDING_INVOICES', outstandingInvoices);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoices',
        value: false,
      });
    }
  },
  getOutstandingInvoicesChart: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoicesChart',
        value: true,
      });

      await dispatch(
        'charts/fetchOutstandingInvoicesChart',
        {
          companyId,
          users,
          search,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoicesChart',
        value: false,
      });
    }
  },
  getPaymentGatewayChart: async ({ commit, dispatch }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'paymentGatewayChart',
        value: true,
      });

      await dispatch(
        'charts/fetchPaymentGatewayChart',
        {
          companyId,
          dateRange: [startOfXMonthsAgoUtc(3), Date.now()],
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'paymentGatewayChart',
        value: false,
      });
    }
  },
  getPaymentsHistoryChart: async ({ commit, dispatch }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'paymentsHistoryChart',
        value: true,
      });

      await dispatch(
        'charts/fetchPaymentsHistoryChart',
        {
          companyId: Vue.auth.user().selectedCompany.id,
          dateRange: [startOfXMonthsAgoUtc(3), Date.now()],
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'paymentsHistoryChart',
        value: false,
      });
    }
  },
  getOutstandingInvoicesAmountChart: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoicesAmountChart',
        value: true,
      });

      await dispatch(
        'charts/fetchOutstandingInvoicesAmountChart',
        {
          companyId,
          users,
          search,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoicesAmountChart',
        value: false,
      });
    }
  },
  getCreditLimitUsageChart: async ({ commit, dispatch }, payload) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'creditLimitUsage',
        value: true,
      });

      await dispatch('charts/setCreditLimitUsageChart', payload, { root: true });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'creditLimitUsage',
        value: false,
      });
    }
  },
  getCustomersRiskChart: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'customersRiskChart',
        value: true,
      });

      await dispatch(
        'charts/fetchCustomerRiskChart',
        {
          companyId,
          users,
          search,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'customersRiskChart',
        value: false,
      });
    }
  },
  getDSOWidget: async ({ commit, dispatch }, { companyId, customerId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'dso',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyDSO', {
          name: 'dso',
          companyId,
          periodType: 'monthly',
          customerId,
          dateRange: [startOfPreviousMonthUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
        dispatch('fetchDSOTrend', {
          name: 'dso',
          companyId,
          customerId,
          periodType: 'monthly',
          dateRange: [startOfTwoMonthsAgoUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'dso',
        value: false,
      });
    }
  },
  getCEIWidget: async ({ commit, dispatch }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'cei',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyCEI', {
          name: 'cei',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfPreviousMonthUtc(), startOfPreviousMonthUtc()],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'cei',
        value: false,
      });
    }
  },
  getDSOStandardWidget: async ({ commit, dispatch }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'dsoStandard',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyDSOStandard', {
          name: 'dsoStandard',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfPreviousMonthUtc(), startOfPreviousMonthUtc()],
        }),
        dispatch('fetchDSOStandardTrend', {
          name: 'dsoStandard',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfTwoMonthsAgoUtc(), startOfPreviousMonthUtc()],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'dsoStandard',
        value: false,
      });
    }
  },
  getADDWidget: async ({ commit, dispatch }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'add',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyADD', {
          name: 'add',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfPreviousMonthUtc(), startOfPreviousMonthUtc()],
        }),
        dispatch('fetchADDTrend', {
          name: 'add',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfTwoMonthsAgoUtc(), startOfPreviousMonthUtc()],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'add',
        value: false,
      });
    }
  },
  getMDDWidget: async ({ commit, dispatch }, { companyId, customerId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'mdd',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyMDD', {
          name: 'mdd',
          companyId,
          customerId,
          periodType: 'monthly',
          dateRange: [startOfPreviousMonthUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
        dispatch('fetchMDDTrend', {
          name: 'mdd',
          companyId,
          customerId,
          periodType: 'monthly',
          dateRange: [startOfTwoMonthsAgoUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'mdd',
        value: false,
      });
    }
  },
  getTopCustomersWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'topDebtors',
        value: true,
      });

      await dispatch(
        'customers/fetchCustomersMetrics',
        {
          companyId,
          users,
          type: 'top',
          sort: { 'stats.totalLocalBalance': -1 },
          page: 1,
          perPage: 5,
          search: search.customer,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'topDebtors',
        value: false,
      });
    }
  },
  getOverdueCustomersWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'pastDueCustomers',
        value: true,
      });

      await Promise.all([
        dispatch(
          'customers/fetchCustomersMetrics',
          {
            companyId,
            users,
            type: 'overdue',
            sort: { 'stats.overdueLocalBalance': -1 },
            page: 1,
            perPage: 5,
            search: search.customer,
          },
          { root: true },
        ),
        dispatch(
          'customers/fetchCountOverdueCustomers',
          {
            companyId,
            search,
          },
          { root: true },
        ),
        dispatch('fetchTrend', {
          companyId,
          name: 'countCustomersOverdue',
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'pastDueCustomers',
        value: false,
      });
    }
  },
  getClosedInvoicesData: async ({ commit, dispatch }, { companyId, users, period, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'closedInvoices',
        value: true,
      });

      await dispatch('fetchClosedInvoicesData', {
        companyId,
        period,
        users,
        search,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'closedInvoices',
        value: false,
      });
    }
  },
  getWorkflowsActivitiesCount: async ({ commit, dispatch }, { companyId, users, period, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'workflowsActivities',
        value: true,
      });

      await dispatch('fetchWorkflowsActivitiesCount', {
        companyId,
        period,
        users,
        search,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'workflowsActivities',
        value: false,
      });
    }
  },
  getNotesActivitiesCount: async ({ commit, dispatch }, { companyId, users, period, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'notesActivities',
        value: true,
      });

      await dispatch('fetchNotesActivitiesCount', {
        companyId,
        period,
        users,
        search,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'notesActivities',
        value: false,
      });
    }
  },
  getManualNotesActivitiesCount: async ({ commit, dispatch }, { companyId, users, period, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'manualNotesActivities',
        value: true,
      });

      await dispatch('fetchManualNotesActivitiesCount', {
        companyId,
        period,
        users,
        search,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'manualNotesActivities',
        value: false,
      });
    }
  },
  getPaymentsForecastWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'payments',
        value: true,
      });

      await Promise.all([
        dispatch(
          'charts/fetchPastPayment',
          {
            companyId,
            periods: [previousMonth(), currentMonth()],
            users,
            search,
          },
          { root: true },
        ),
        dispatch(
          'charts/fetchPaymentForecasting',
          {
            companyId,
            users,
            search,
          },
          { root: true },
        ),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'payments',
        value: false,
      });
    }
  },
  delayPaymentForecastWidget: async ({ commit }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'payments',
        value: true,
      });

      await setTimeout(() => {
        commit('SET_LOADING_WIDGETS', {
          widgetId: 'payments',
          value: false,
        });
      }, 1000);
    } catch ({ message }) {
      console.error(message);
    }
  },
  getCustomersChartWidget: async ({ commit, dispatch }, { companyId, users, UCRType, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'customersChart',
        value: true,
      });

      await dispatch(
        'charts/fetchCustomersChart',
        {
          companyId,
          users,
          UCRType,
          sort: { 'stats.totalLocalBalance': -1 },
          page: 1,
          perPage: 100,
          search: search.customer,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'customersChart',
        value: false,
      });
    }
  },
  getInvoicesChartWidget: async ({ commit, dispatch }, { companyId, users, search }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'invoicesChart',
        value: true,
      });

      await dispatch(
        'charts/fetchInvoicesChart',
        {
          companyId,
          users,
          sort: { 'stats.totalLocalBalance': -1 },
          page: 1,
          perPage: 100,
          search,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'invoicesChart',
        value: false,
      });
    }
  },
  setFilterWidgets: ({ commit }, { widgetId, value }) => {
    commit('SET_FILTER_WIDGETS', {
      widgetId,
      value,
    });
  },
  getRatesWidget: async ({ commit, dispatch }, { companyId, rate, country }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: rate,
        value: true,
      });

      await dispatch('rates/fetchRates', { companyId, rate, country }, { root: true });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: rate,
        value: false,
      });
    }
  },
  getCollectionRateWidget: async ({ commit, dispatch }, { companyId, customerId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'collectionRate',
        value: true,
      });

      await Promise.all([
        dispatch('fetchMonthlyCollectionRate', {
          name: 'collectionRate',
          companyId,
          periodType: 'monthly',
          customerId,
          dateRange: [startOfPreviousMonthUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
        dispatch('fetchCollectionRateTrend', {
          name: 'collectionRate',
          companyId,
          periodType: 'monthly',
          customerId,
          dateRange: [startOfTwoMonthsAgoUtc(), endOfDayUtc(startOfPreviousMonthUtc())],
        }),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'collectionRate',
        value: false,
      });
    }
  },
  getManualTimeSavedWidget: async ({ commit, dispatch }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'manualTimeSaved',
        value: true,
      });

      await dispatch('fetchManualTimeSaved');
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'manualTimeSaved',
        value: false,
      });
    }
  },
};
