import { httpClient } from '@api';

export default {
  updatePassword: async (payload) => httpClient.post('auth/update/password', payload),
  resetPassword: async () => httpClient.post('user/reset/password/request'),
  validateOtp: async (payload) => httpClient.post('auth/validate/otp', payload),
  fetchSsoEntryPoint: async (payload) => httpClient.post('auth/sso/entryPoint', payload),
  ssoLogin: async (payload) => httpClient.post('auth/sso/login', payload),
  reason: async (payload) => httpClient.post('auth/support', payload),
  userExists: async (payload) => httpClient.post('user/exists', payload),
};
