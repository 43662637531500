import { httpClient } from '@api';

export default {
  create: async (payload) => httpClient.post('company/add/new', payload),
  fetchMetricMeasurements: async (payload) => httpClient.post('company/metrics/measurements', payload),
  fetchMetric: async (payload) => httpClient.post('company/metric', payload),
  fetchSettingsByCompanyId: async (payload) => httpClient.post('company/data/find', payload),
  fetchSpecificSettingsByCompanyId: async (payload) => httpClient.post('company/settings', payload),
  updateSettings: async (payload) => httpClient.post('company/data/update', payload),
  fetchUsers: async (payload) => httpClient.post('company/users', payload),
  createUser: async (payload) => httpClient.post('company/users/insert', payload),
  updateCompanyUser: async (payload) => httpClient.post('company/users/update', payload),
  updateUser: async (payload) => httpClient.post('user/update', payload),
  updateUserSettings: async (payload) => httpClient.post('company/users/update/settings', payload),
  fetchUserHeaders: async (payload) => httpClient.post('company/user/headers', payload),
  fetchAdaptersByCompanyId: async ({ companyId }) => httpClient.get(`adapters/company/${companyId}`),
  updateAdapter: async (payload) => httpClient.post('adapters/company', payload),
  runAdapterIndex: async ({ name, token, body, contentType }) =>
    httpClient.post(`adapter/internal-run/${name}?token=${token}`, body, {
      headers: { 'Content-Type': contentType },
    }),
  runAdapterMethod: async (payload) => httpClient.post('adapter/method/run', payload),
  createMerchant: async (payload) => httpClient.post('payment/merchant/create', payload),
  findMerchant: async (payload) => httpClient.post('payment/merchant/find', payload),
  checkWorkflowConfigurations: async (payload) => httpClient.post('company/check/workflow/configurations', payload),
  getTemplateFile: async (payload) => httpClient.post('integration/template', payload),
  uploadLogo: async (payload) => httpClient.post('company/upload-logo', payload),
  plaidLinkToken: async (payload) => httpClient.post('plaid/link-token', payload),
  plaidConnect: async (payload) => httpClient.post('plaid/connect', payload),
  plaidReconnectLinkToken: async (payload) => httpClient.post('plaid/reconnect-link-token', payload),
  plaidReconnect: async (payload) => httpClient.post('plaid/reconnect', payload),
};
