import { isEqual } from 'lodash-es';
import { i18n } from '@plugins';
import Vue from 'vue';

export default {
  widgets: (_state, _getters, _rootState, rootGetters) => {
    const {
      'charts/dsoChart': dsoChart,
      'charts/totalARCompareChart': totalARCompareChart,
      'charts/overdueCustomersCompareChart': overdueCustomersCompareChart,
      'charts/outstandingInvoicesCompareChart': outstandingInvoicesCompareChart,
      'charts/agingBucketsCompareChart': agingBucketsCompareChart,
      'charts/paymentsForecastCompareChart': paymentsForecastCompareChart,
      'charts/collectionRateCompareChart': collectionRateCompareChart,
      'settings/uiShowPaymentForecasting': uiShowPaymentForecasting,
    } = rootGetters;

    const isDemoCompany = isEqual(Vue.auth.user().selectedCompany.type, 'demo');

    return [
      {
        id: 'totalAR',
        title: i18n.t('dashboard.totalAR'),
        chart: totalARCompareChart,
        toggleButtons: false,
        compareDates: true,
        showWidget: true,
        isFeatureDisabled: false,
      },
      {
        id: 'agingBuckets',
        title: i18n.t('dashboard.agingBuckets'),
        chart: agingBucketsCompareChart,
        toggleButtons: false,
        compareDates: true,
        showWidget: true,
        isFeatureDisabled: false,
      },
      {
        id: 'openInvoices',
        title: i18n.t('dashboard.openInvoices'),
        chart: outstandingInvoicesCompareChart,
        toggleButtons: false,
        compareDates: true,
        showWidget: true,
        isFeatureDisabled: false,
      },
      {
        id: 'collectionRate',
        title: i18n.t('dashboard.collectionRateTrend'),
        tooltip: i18n.t('dashboard.help.collectionRateTrend'),
        chart: collectionRateCompareChart,
        toggleButtons: false,
        compareDates: false,
        showWidget: true,
        isFeatureDisabled: false,
      },
      {
        id: 'dso',
        title: i18n.t('dashboard.dso'),
        chart: dsoChart,
        toggleButtons: false,
        compareDates: false,
        showWidget: true,
        isFeatureDisabled: false,
      },
      {
        id: 'payments',
        title: i18n.t('dashboard.payments'),
        chart: paymentsForecastCompareChart,
        toggleButtons: true,
        compareDates: false,
        showWidget: true,
        isFeatureDisabled: !isDemoCompany && !uiShowPaymentForecasting,
      },
      {
        id: 'pastDueCustomers',
        title: i18n.t('dashboard.pastDueCustomers'),
        chart: overdueCustomersCompareChart,
        toggleButtons: false,
        compareDates: true,
        showWidget: true,
        isFeatureDisabled: false,
      },
    ];
  },
};
