const CUSTOM_FIELDS = {
  FIELDS: {
    CUSTOMER: 'customer',
    INVOICE: 'invoice',
  },
  TYPES: {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    DATE: 'date',
  },
};

Object.freeze(CUSTOM_FIELDS);

export default CUSTOM_FIELDS;
