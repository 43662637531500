import { startOfWeek, endOfWeek, addWeeks, startOfYear, endOfYear, addYears } from 'date-fns';
import { isEqual } from 'lodash-es';
import Vue from 'vue';

const startOfDayUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth(), now.getDate());
};

const startOfPreviousMonthUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth() - 1, 1);
};

const startOfMonthUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth(), 1);
};

const lastDayOfPreviousMonth = () => {
  const now = new Date();

  return Date.UTC(now.getFullYear(), now.getMonth(), 1) - 1;
};

const lastDayOfMonth = () => {
  const now = new Date();

  return Date.UTC(now.getFullYear(), now.getMonth() + 1, 1) - 1;
};

const startOfTenMonthsAgoUtc = () => {
  const now = new Date();

  return Date.UTC(now.getUTCFullYear(), now.getMonth() - 10, 1);
};

const previousMonth = () => [startOfPreviousMonthUtc(), lastDayOfPreviousMonth()];

const currentMonth = () => [startOfMonthUtc(), lastDayOfMonth()];

const currentWeek = (weekStartsOn) => [
  startOfWeek(startOfDayUtc(), { weekStartsOn }).getTime(),
  endOfWeek(startOfDayUtc(), { weekStartsOn }).getTime(),
];

const previousWeek = (weekStartsOn) => [
  startOfWeek(addWeeks(startOfDayUtc(), -1), { weekStartsOn }).getTime(),
  endOfWeek(addWeeks(startOfDayUtc(), -1), { weekStartsOn }).getTime(),
];

const twoWeeksAgo = (weekStartsOn) => [
  startOfWeek(addWeeks(startOfDayUtc(), -2), { weekStartsOn }).getTime(),
  endOfWeek(addWeeks(startOfDayUtc(), -2), { weekStartsOn }).getTime(),
];

export default {
  getTotalARCompareChart: async ({ dispatch, commit }, { companyId, dateRange }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'totalAR',
        value: true,
      });

      const action = isEqual(Vue.auth.user().selectedCompany.type, 'demo')
        ? 'charts/fetchTotalARCompareDemoChart'
        : 'charts/fetchTotalARCompareChart';

      await dispatch(
        action,
        {
          companyId,
          dateRange,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'totalAR',
        value: false,
      });
    }
  },
  getOverdueCustomersCompareChart: async ({ dispatch, commit }, { companyId, dateRange }) => {
    commit('SET_LOADING_WIDGETS', {
      widgetId: 'pastDueCustomers',
      value: true,
    });

    try {
      const action = isEqual(Vue.auth.user().selectedCompany.type, 'demo')
        ? 'charts/fetchOverdueCustomersCompareDemoChart'
        : 'charts/fetchOverdueCustomersCompareChart';

      await dispatch(
        action,
        {
          companyId,
          dateRange,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'pastDueCustomers',
        value: false,
      });
    }
  },
  getOutstandingInvoicesCompareChart: async ({ dispatch, commit }, { companyId, dateRange }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoices',
        value: true,
      });

      const action = isEqual(Vue.auth.user().selectedCompany.type, 'demo')
        ? 'charts/fetchOutstandingInvoicesCompareDemoChart'
        : 'charts/fetchOutstandingInvoicesCompareChart';

      await dispatch(
        action,
        {
          companyId,
          dateRange,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'openInvoices',
        value: false,
      });
    }
  },
  getAgingBucketsCompareChart: async ({ dispatch, commit }, { companyId, dateRange }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'agingBuckets',
        value: true,
      });

      const action = isEqual(Vue.auth.user().selectedCompany.type, 'demo')
        ? 'charts/fetchAgingBucketsCompareDemoChart'
        : 'charts/fetchAgingBucketsCompareChart';

      await dispatch(
        action,
        {
          companyId,
          dateRange,
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'agingBuckets',
        value: false,
      });
    }
  },
  getPaymentsForecastCompareChart: async ({ dispatch, rootGetters, commit }, { companyId, type, numberOfWeeks }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'payments',
        value: true,
      });

      if (isEqual(Vue.auth.user().selectedCompany.type, 'demo')) {
        dispatch(
          'charts/fetchPaymentForecastingCompareDemoChart',
          {
            type,
          },
          { root: true },
        );
      } else {
        const weekStartsOn = rootGetters['settings/weekStartsOn'];

        const action = isEqual(type, 'weekly')
          ? 'charts/fetchPastWeeklyPaymentCompareChart'
          : 'charts/fetchPastMonthlyPaymentCompareChart';

        const periods = isEqual(type, 'weekly')
          ? [twoWeeksAgo(weekStartsOn), previousWeek(weekStartsOn), currentWeek(weekStartsOn)]
          : [previousMonth(), currentMonth()];

        await Promise.all([
          dispatch(
            action,
            {
              companyId,
              periods,
            },
            { root: true },
          ),
          dispatch(
            'charts/fetchPaymentForecastingCompareChart',
            {
              companyId,
              type,
              numberOfWeeks,
            },
            { root: true },
          ),
        ]);
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'payments',
        value: false,
      });
    }
  },
  getCollectionRateCompareChart: async ({ dispatch, commit }, { companyId }) => {
    try {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'collectionRate',
        value: true,
      });

      if (isEqual(Vue.auth.user().selectedCompany.type, 'demo')) {
        dispatch('charts/fetchCollectionRateDemoCompareChart', {}, { root: true });
      } else {
        const previousYear = addYears(new Date(), -1);
        const periods = [
          [startOfYear(previousYear).getTime(), endOfYear(previousYear).getTime()],
          [startOfYear(new Date()).getTime(), endOfYear(new Date()).getTime()],
        ];

        await dispatch(
          'charts/fetchCollectionRateCompareChart',
          {
            companyId,
            periods,
          },
          { root: true },
        );
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_LOADING_WIDGETS', {
        widgetId: 'collectionRate',
        value: false,
      });
    }
  },
  getDSOChart: async ({ dispatch }, { companyId }) => {
    try {
      await dispatch(
        'charts/fetchDsoChart',
        {
          name: 'dso',
          companyId,
          periodType: 'monthly',
          dateRange: [startOfTenMonthsAgoUtc(), startOfPreviousMonthUtc()],
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
};
