const PERMISSIONS = {
  RESOURCES: {
    DASHBOARDS: 'dashboards',
    CHART_CENTRAL: 'chart-central',
    ACTIVITY_LOG: 'activity-log',
    ACTIVITY_LOG_EXPORT: 'activity-log-export',
    AR_TEAM: 'ar-team',
    DOCUMENTS: 'documents',
    MAILER: 'mailer',
    MY_ACTIONS: 'my-actions',
    NOTIFICATIONS: 'notifications',
    INVOICES: 'invoices',
    INVOICES_EXPORT: 'invoices-export',
    INVOICES_UPLOAD_BULK_FILES: 'invoices-upload-bulk-files',
    INVOICES_ATTACHMENT: 'invoices-attachment',
    CUSTOMERS: 'customers',
    CUSTOMERS_EXPORT: 'customers-export',
    WORKFLOWS: 'workflows',
    WORKFLOWS_EXPORT: 'workflows-export',
    WORKFLOWS_RUN: 'workflows-run',
    PREFERENCES_WORKFLOWS_MASTER_SWITCH: 'preferences-workflows-master-switch',
    PREFERENCES_COMPANY: 'preferences-company',
    PREFERENCES_DISPLAY_OPTIONS: 'preferences-display-options',
    PREFERENCES_AP_ASSISTANT: 'preferences-ap-assistant',
    PREFERENCES_WORKFLOWS: 'preferences-workflows',
    PREFERENCES_PAYMENTS: 'preferences-payments',
    PREFERENCES_ALERTS: 'preferences-alerts',
    PREFERENCES_CUSTOMERS_INVOICES: 'preferences-customers-invoices',
    INSIGHTS: 'insights',
    INTEGRATIONS: 'integrations',
    INTEGRATIONS_UPLOAD_FILE: 'integrations-upload-file',
    INTEGRATIONS_RUN_ADAPTER: 'integrations-run-adapters',
    USERS: 'users',
    USER_COMPANY_SETTINGS: 'user-company-settings',
    ROLES: 'roles',
    PARTNERS: 'partners',
    CREDIT_MANAGEMENT: 'credit',
    BANK_RECONCILIATION: 'bank-reconciliation',
  },
  POSSESSIONS: {
    ANY: 'any',
    OWN: 'own',
  },
  ACTIONS: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
  },
  ROLE_ID: {
    SUPPORT: '4976a37a-471d-4467-ae46-eec7f104cd59',
  },
};

Object.freeze(PERMISSIONS);

export default PERMISSIONS;
