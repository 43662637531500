import { isEqual, isNil } from 'lodash-es';
import VueSplit from 'vue-split-panel';
import * as Sentry from '@sentry/vue';
import PortalVue from 'portal-vue';
import Toasted from 'vue-toasted';
import Vue from 'vue';

import { http, i18n, vuetify, loadFonts, VueIntercom, VueAnalytics } from '@plugins';
import { ENVS, DOMAINS } from '@enums';
import { ignoreErrors } from '@utils';
import router from '@router';
import store from '@store';

import * as auth from '@plugins/auth';

import globalDirectives from '@/directives';

import '@plugins/turnstile';
import '@plugins/clarity';
import '@plugins/plaid';
import '@plugins/notice';
import '@plugins/can';

import '@filters/formatCurrency';

import App from '@/App';

const { VITE_APP_DEBUG_MODE, MODE, VITE_APP_INTERCOM_APP_ID, VITE_APP_SENTRY_DSN, VITE_APP_GA_ID } = import.meta.env;

const isDebugMode = !isNil(VITE_APP_DEBUG_MODE) && isEqual(VITE_APP_DEBUG_MODE, 'true');
const isProduction = isEqual(MODE, ENVS.PRODUCTION);
const isDevelopment = isEqual(MODE, ENVS.DEVELOPMENT);

Vue.use(Toasted);
Vue.use(VueSplit);
Vue.use(VueIntercom, { appId: VITE_APP_INTERCOM_APP_ID });
Vue.use(PortalVue);
Vue.use(VueAnalytics, { id: VITE_APP_GA_ID, debug: isDevelopment || isDebugMode });

const tracePropagationTargets = [
  DOMAINS.LOCALHOST,
  DOMAINS.LOCALHOST_PROD,
  DOMAINS.DEV1,
  DOMAINS.DEV2,
  DOMAINS.DEV3,
  DOMAINS.STAGING,
  DOMAINS.EU,
  DOMAINS.US,
  /^\//,
];

const sentryEnvironment = (() => {
  const hostname = window.location.hostname;

  const envsMap = {
    [tracePropagationTargets[0]]: ENVS.DEVELOPMENT, // localhost
    [tracePropagationTargets[1]]: ENVS.PRODUCTION, // kubernetes.docker.internal
    [tracePropagationTargets[2]]: ENVS.DEVELOPMENT, // dev1.app.gaviti.com
    [tracePropagationTargets[3]]: ENVS.DEVELOPMENT, // dev2.app.gaviti.com
    [tracePropagationTargets[4]]: ENVS.DEVELOPMENT, // dev3.app.gaviti.com
    [tracePropagationTargets[5]]: ENVS.STAGING, // stage.app.gaviti.com
    [tracePropagationTargets[6]]: ENVS.PRODUCTION, // app.gaviti.com
    [tracePropagationTargets[7]]: ENVS.PRODUCTION, // us.app.gaviti.com
  };

  return envsMap[hostname] || ENVS.DEVELOPMENT;
})();

Sentry.init({
  Vue,
  dsn: VITE_APP_SENTRY_DSN,
  environment: sentryEnvironment,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  attachProps: true,
  logErrors: true,
  ignoreErrors,
  debug: isDevelopment && isDebugMode,
  enabled: isProduction,
});

loadFonts();

Vue.config.productionTip = false;
Vue.config.devtools = isDevelopment || isDebugMode;

Vue.prototype.$app = {
  name: 'Gaviti',
};

Vue.use(globalDirectives);

new Vue({
  http,
  store,
  router,
  auth,
  i18n,
  vuetify,
  render: (h) => h(App),
  beforeCreate() {
    this.$turnstile.setup();

    if (localStorage.sidebar) {
      this.$store.dispatch('app/toggleSidebar', JSON.parse(localStorage.sidebar));
    }
  },
  created() {
    this.$plaid.init();
  },
  mounted() {
    if (isProduction) {
      this.$clarity.setup();
    }
  },
}).$mount('#app');
