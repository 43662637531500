const FILES = {
  FILES_ALLOWED_FOR_PREVIEW: [
    'image/png',
    'image/jpeg',
    'image/tiff',
    'application/pdf',
    'application/zip',
    'application/x-7z-compressed',
    'application/x-tar',
  ],
};

Object.freeze(FILES);

export default FILES;
